import "./CreateMasterAccountRevampStyle.scss";
import { Radio, Space, Input, Select, message, Spin } from "antd";
import { handleIntegerInput } from "component/utilis/constant";
import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { postApiWithAuth, getApiWithAuth } from "component/utilis/apiWrapper";
import { Modal } from "antd";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";

const CreateMasterAccountRevamp = ({ setIsCreated }) => {
  const [masterAccountData, setMasterAccoutData] = useState({
    account_number: "",
    account_name: "",
    account_password: "",
    account_type: "MT4",
    broker_name: "",
  });
  const [serverList, setServerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getServerList = async () => {
    const response = await getApiWithAuth("accounts/broker-servers");
    if (response.success) {
      setServerList(response.data.broker_server_list);
      setSelectedValue(response.data.broker_server_list[0]);
      setMasterAccoutData({
        ...masterAccountData,
        broker_name: response.data.broker_server_list[0],
      });
    } else {
      message.error(`${response.message.data.error}`);
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMasterAccoutData({ ...masterAccountData, [name]: value });
    if (name == "broker_name") {
      setSelectedValue(value);
    }
  };
  const handleChange = (value, name) => {
    setSelectedValue(value);
    setMasterAccoutData({ ...masterAccountData, [name]: value });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const createMasterAccount = async () => {
    setIsLoading(true);
    const response = await postApiWithAuth(
      "accounts/add-master-account/",
      masterAccountData
    );
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Account created successfully");
    setIsCreated(false);
  };

  useEffect(() => {
    getServerList();
  }, []);
  return (
    <>
      <div className="createMasterAccoutTextStyle">Create Master Account</div>
      <div className="masterRevampSecondContainer">
        <div className="accountContainerRevampStyle">
          <div className="AccoutTypeStyle">Account Type:</div>

          <div className="AccoutTypeRadioStyle">
            <Radio.Group
              onChange={handleOnChange}
              value={masterAccountData.account_type}
              name="account_type"
            >
              <Space direction="horizontal" className="createMasterRevampRadio">
                <div>
                  <Radio value={"MT4"}>Metatrader 4(MT4)</Radio>
                </div>
                <div>
                  <Radio value={"MT5"}>Metatrader 5(MT5)</Radio>
                </div>
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className="masterinputFieldContainerRevampRight">
          <div className="AccoutTypeStyle">Account Name:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="masterAccountInputStyle"
              name="account_name"
              value={masterAccountData.account_name}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="masterinputFieldContainerRevampRight">
          <div className="AccoutTypeStyle">Account Number:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="masterAccountInputStyle"
              name="account_number"
              value={masterAccountData.account_number}
              onChange={handleOnChange}
              onInput={handleIntegerInput}
            />
          </div>
        </div>
        <div className="masterinputFieldContainerRevampRight">
          <div className="AccoutTypeStyle">Account Password:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="masterAccountInputStyle"
              name="account_password"
              value={masterAccountData.account_password}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="brokenConfigRevampStyle">
          <div>
            <div>
              <div className="createMasterAccoutRevampTextStyle">
                Broker Configuration:
              </div>
              <div className="symbolMappingParaTwoStyle">
                Choose an existing broker from the list
              </div>
            </div>
            <div className="AccoutTypeStyle">Broker Server:</div>
            <div>
              <Select
                placeholder="Select Broker Server"
                className="custom-select-broker"
                name="broker_name"
                onChange={(value) => handleChange(value, "broker_name")}
                style={{
                  width: "80%",
                  marginTop: "2%",
                }}
                value={selectedValue}
              >
                {isLoading ? (
                  <Select.Option>
                    <Spin indicator={antIcon} />
                  </Select.Option>
                ) : (
                  serverList?.map((server) => (
                    <Select.Option key={server} value={server}>
                      {server}
                    </Select.Option>
                  ))
                )}
              </Select>
            </div>
          </div>
          <div className="symbolMappingParaTwoStyle">
            If your Broker server is not listed in the drop down options above,
            <br />
            <u className="createAccountStyle" onClick={showModal}>
              Click here{" "}
            </u>
            &nbsp; to add your broker
          </div>
          <div>
            <div>
              <MetaSyncButton
                text="Add Account"
                className="masterCreateEditrevampButtonStyle"
                onClick={() => createMasterAccount()}
                isLoading={isLoading}
                disabled={
                  masterAccountData.account_name == "" ||
                  masterAccountData.account_number == "" ||
                  masterAccountData.account_password == "" ||
                  masterAccountData.account_type == "" ||
                  masterAccountData.broker_name == ""
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="brokerrevampModal">
          <div className="AccountHeadingContainer">
            <div className="AccoutTypeStyle">Add Your Broker Server</div>
          </div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="masterAccountInputRevampStyle"
              name="broker_name"
              value={masterAccountData.broker_name}
              onChange={handleOnChange}
            />
          </div>
          <div className="AccountHeadingContainer">
            <div>
              <MetaSyncButton
                text="Add Broker"
                className="masterCreateEditrevampButtonStyle"
                onClick={() => handleCancel()}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CreateMasterAccountRevamp;
