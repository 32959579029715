import logo from "assets/images/metaCopierLogo.svg";
import { useState } from "react";
import { MetaSyncButton } from "component/commonCompnent";
import HomePageMainImage from "assets/images/homePageImg.svg";

const HomePageTelegramCopier = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className="metaTraderCopierContainerStyle">
        <div className="copierLogoContainerStyle">
          <img src={logo} />
          <span className="metaCopierHeadingStyle">Telegram Copier</span>
        </div>
        <div>
          <nav style={{ margin: "2% 2% 1% 2%" }}>
            <div className="homeNavMainStyle">
              <div className="featureNavStyle">
                <div
                  className={
                    activeTab === 0
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(0)}
                >
                  <div>Fast Execution</div>
                </div>
                <div
                  className={
                    activeTab === 1
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(1)}
                >
                  <div>No VPS</div>
                </div>
                <div
                  className={
                    activeTab === 2
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(2)}
                >
                  <div>Prop Firm Friendly</div>
                </div>
                <div
                  className={
                    activeTab === 3
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(3)}
                >
                  <div>Copy Multiple Channels</div>
                </div>
                <div
                  className={
                    activeTab === 4
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(4)}
                >
                  <div>Full Trade Management</div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="fastImageContainerStyle">
          <div className="fastExecutionContainerStyle">
            <div className="fastExecutionContentStyle">
              <div className="fastTextStyle">⚡Fast Execution</div>
              <div className="fastParaStyle">
                Users will be requested to add billing information and renew
                their account access there after. Users will be requested to add
                billing information and renew their account access there after.
                Users will be requested to add billing information and renew
                their account access there after.{" "}
              </div>
              <div>
                <MetaSyncButton text="View All Features" />
              </div>
            </div>
          </div>
          <div>
            <img
              src={HomePageMainImage}
              className="featureHomePageImageStyle"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageTelegramCopier;
