import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getToken } from "component/utilis/localStorage";
import { HomePageNavbar, HomePageFooter } from "component/commonCompnent";

const PublicRoute = ({ Restricted }) => {
  const token = getToken();
  const location = useLocation();

  const shouldRedirectToDashboard =
    token && Restricted && location.pathname !== "/dashboard";

  return (
    <>
      {shouldRedirectToDashboard ? (
        <Navigate to="/dashboard" />
      ) : location.pathname === "/loading" || location.pathname === "/login" ? (
        <div className="pricingBackground">
          <Outlet />
        </div>
      ) : (
        <div className="pricingBackground">
          <div>
            <HomePageNavbar />
          </div>
          <Outlet />
          <div>
            <HomePageFooter />
          </div>
        </div>
      )}
    </>
  );
};

export default PublicRoute;
