import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddPaymentCard from "../AddPaymentCard";

const Billing = ({
  setIsAddSubscription,
  billingDetailData,
  isUpdate,
  setIsModalPaidOpen,
}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <>
      <Elements stripe={stripePromise}>
        <AddPaymentCard
          setIsAddSubscription={setIsAddSubscription}
          billingDetailData={billingDetailData}
          isUpdate={isUpdate}
          setIsModalPaidOpen={setIsModalPaidOpen}
        />
      </Elements>
    </>
  );
};
export default Billing;
