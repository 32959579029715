import { Input } from "antd";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import WhiteTick from "assets/images/metaWhiteTick.svg";
import PhoneImage from "assets/images/phoneImage.svg";
import ChatImage from "assets/images/chatImage.svg";
import EmailImage from "assets/images/emailImage.svg";
import contactFacebook from "assets/images/contactFacebook.svg";
import contactTwitter from "assets/images/contactTwitter2.svg";
import contactLinkden from "assets/images/contactLinkdin2.svg";
import { message } from "antd";
import { postApiWithoutAuth } from "component/utilis/apiWrapper";
import { handleIntegerInput } from "component/utilis/constant";

import "./MetaContactStyle.scss";
import { useState } from "react";
const MetaContact = () => {
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    subject: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const sendMessage = async () => {
    setIsLoading(true);
    const response = await postApiWithoutAuth(
      "contact-us/create/",
      contactData
    );
    setIsLoading(false);

    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Message send successfully");
  };
  const phoneNumberRegex = /^\+?[0-9+]*$/;
  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    if (phoneNumberRegex.test(value) || value === "") {
      setContactData({ ...contactData, [name]: value });
    }
  };

  return (
    <>
      <div className="homeourfeatureStyle">Contact Sales</div>
      <div className="homefeatureParaStyle">
        The TradeMimic platform is designed to provide brokers and traders with
        a <br />
        one-stop solution for executing trades across multiple accounts.
      </div>
      <div className="contactContainer">
        <div className="contactFormStyle">
          <div className="masterinputFieldContainer">
            <div className="masterinputFieldContainerLeft">
              <div className="contactTagStyle">Name:</div>
              <div>
                <Input
                  placeholder="Enter your name"
                  className="masterAccountInputStyle"
                  name="name"
                  value={contactData.name}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="masterinputFieldContainerRight">
              <div className="contactTagStyle">Email Address</div>
              <div>
                <Input
                  placeholder="Enter your email"
                  className="masterAccountInputStyle"
                  name="email"
                  value={contactData.email}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>

          <div className="messageContainerStyle">
            <div className="contactTagStyle">Message</div>
            <TextArea
              rows={4}
              style={{ width: "95%", marginTop: "1%" }}
              placeholder="Hi there! I would like to talk you some................"
              name="message"
              value={contactData.message}
              onChange={handleOnChange}
            />
          </div>
          <MetaSyncButton
            text="Send Message"
            onClick={sendMessage}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div>
        <div className="homeourfeatureStyle">We’re here to help</div>
        <div className="helpContainer">
          <div className="solutionContainer">
            <img src={WhiteTick} />
            <div className="helpTextstyle">Find the right solution for you</div>
          </div>
          <div className="solutionContainer">
            <img src={WhiteTick} />
            <div className="helpTextstyle">
              Explain general enterprise contract
            </div>
          </div>
          <div className="solutionContainer">
            <img src={WhiteTick} />
            <div className="helpTextstyle">Provide helpful resources</div>
          </div>
        </div>
        <div style={{ margin: "0 3% 0 3%" }}>
          <hr />
          <div className="needHelpContainer">
            <div className="needHelpDivStyle">
              <div className="contactTagStyle">Need Help?</div>
              <div className="solutionContainer">
                <img src={PhoneImage} width={"20px"} />
                <div className="helpTextstyle">1-800-123-456</div>
              </div>
              <div className="solutionContainer">
                <img src={ChatImage} width={"20px"} />
                <div className="helpTextstyle">Live Chat</div>
              </div>
              <div className="solutionContainer">
                <img src={EmailImage} width={"20px"} />
                <div className="helpTextstyle">Email</div>
              </div>
            </div>
            <div className="needHelpIconDivStyle">
              <div className="contactTagStyle">connect:</div>
              <div className="solutionContainer">
                <img src={contactFacebook} width={"50px"} />
              </div>
              <div className="solutionContainer">
                <img src={contactTwitter} width={"50px"} />
              </div>
              <div className="solutionContainer">
                <img src={contactLinkden} width={"50px"} />
              </div>
            </div>
          </div>
          <hr style={{ marginBottom: "6%" }} />
        </div>
      </div>
    </>
  );
};
export default MetaContact;
