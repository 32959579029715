import React, { useEffect } from "react";
import "./NavbarStyle.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import navIcon from "assets/images/navIcon.svg";
import { getApiWithAuth } from "component/utilis/apiWrapper";
import { useState } from "react";

const Navbar = ({ setIsOpen, isOpen, updateUserDataNav }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const userProfileData = async () => {
    const response = await getApiWithAuth("users/get-profile");

    setUserProfile(response.data);
  };

  useEffect(() => {
    userProfileData();
  }, [updateUserDataNav]);
  return (
    <>
      <div className="navbarMainStyle">
        <div>
          <img
            src={navIcon}
            className="navIconStyle"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
        <div className={isOpen ? "profileContainer" : "profileOpenContainer"}>
          <div className="profileInnerContainer">
            <div className="profileImageMainContainerStyle">
              <img
                src={userProfile?.picture}
                className="profileImageContainer"
                width={50}
                height={50}
              />
            </div>
            <div className="userContainer">
              <div className="userNameStyle">{userProfile?.nickname}</div>
              <div
                className="viewProfileStyle"
                onClick={() => navigate("/editUserProfile")}
              >
                View Profile
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
