import "./HomePageFooterStyle.scss";
import MetaSyncLogo from "assets/images/MetaSyncWhiteLogo.svg";
import TwitterIcon from "assets/images/twitterIconFooter.svg";
import GoogleIcon from "assets/images/googleIconFooter.svg";
import LinkdinIcon from "assets/images/linkdinIconFooter.svg";
import FacebookIcon from "assets/images/facebookIconFooter.svg";
import MetaSyncButton from "../metaSyncButton";
import { Button, Input, Select, Space } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const HomePageFooter = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const handleSignup = () => {
    loginWithRedirect({ screen_hint: "signup" });
  };

  return (
    <>
      <div className="footerMainStyle">
        <div className="footerFirstContainerStyle">
          <div className="footerfirstContainerStyle">
            <div>
              <img src={MetaSyncLogo} />
            </div>
            <div>
              The #1 Ai trade copier tool for MetaTrader & Telergam Channels -
              No VPS required The #1 Ai trade cop Channels - No VPS required
            </div>
            <div style={{ paddingTop: "4%" }}>
              <MetaSyncButton
                text="Get Started"
                className="footerButtonStyle"
                onClick={handleSignup}
              />
            </div>
          </div>
          <div className="footerSecContainerStyle">
            <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              Home
            </div>
            <div
              onClick={() => navigate("/contact")}
              style={{ cursor: "pointer" }}
            >
              Contact Us
            </div>
          </div>
          <div className="footerSecContainerStyle">
            <div onClick={() => navigate("/faq")} style={{ cursor: "pointer" }}>
              FAQ
            </div>
            <div
              onClick={() => navigate("/pricing")}
              style={{ cursor: "pointer" }}
            >
              See Pricing
            </div>
          </div>
          <div className="footerEndContainerStyle">
            <div className="footerHeadingStyle">Never miss any update.</div>
            <div>
              Sign up to our newsletter and stay up to date on products
              features.
            </div>
            <div className="emailBorderFooterStyle">
              <Space.Compact
                style={{
                  width: "100%",
                  paddingTop: "7px",
                }}
              >
                <Input
                  style={{ backgroundColor: "#0059e7", border: "none" }}
                  className="emailFooterContainerStyle"
                  placeholder="Email"
                />
                <Button className="footerSubscribeButtonStyle">
                  Subcribe <ArrowRightOutlined />
                </Button>
              </Space.Compact>
            </div>
            <div>support@trademimic.com</div>
            <div className="FooterIconcontainerStyle">
              <div
                onClick={process.env.REACT_APP_TWITTER}
                style={{ cursor: "pointer" }}
              >
                <img src={TwitterIcon} />
              </div>
              <div
                onClick={process.env.REACT_APP_FACEBOOK}
                style={{ cursor: "pointer" }}
              >
                <img src={FacebookIcon} />
              </div>
              <div
                onClick={process.env.REACT_APP_GOOGLE}
                style={{ cursor: "pointer" }}
              >
                <img src={GoogleIcon} />
              </div>
              <div
                onClick={process.env.REACT_APP_LINKDIN}
                style={{ cursor: "pointer" }}
              >
                <img src={LinkdinIcon} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="footerSecurityStyle">
          © 2023 MetaSyc | Security | Your Privacy | Terms
        </div>
      </div>
    </>
  );
};
export default HomePageFooter;
