import { Select, Input, Space, Radio } from "antd";
import {
  handleIntegerInput,
  handleNumericInput,
} from "component/utilis/constant";
import { useState } from "react";
const SubscriberTradeMimic = ({
  handleSelectSubscriberOnChange,
  handleSubscriberOnChange,
  syncAccount,
  tradeVolumeEnable,
  tradeRiskEnable,
  handleOnChange,
}) => {
  const [calculateTrade, setCalculateTrade] = useState(false);
  const [maxRisk, setMaxRisk] = useState(false);
  const [drawDownThreshold, setDrawDownThreshold] = useState(false);
  return (
    <>
      <div className="tradeMimicContainerStyle">
        <div className="accountSyncContainerStyle">
          <div className="accountsyncSubHeading">Trade Mimic Mode:</div>
          <div>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Trade Mimic"
              className="custom-select-accountsync"
              name="risk_settings"
              onChange={(value) => {
                handleSelectSubscriberOnChange(value, "risk_settings");
              }}
              options={[
                {
                  value: 5,
                  label: "Contract Size",
                },
                {
                  value: 4,
                  label: "Balance",
                },
                {
                  value: 2,
                  label: "Fixed Volume",
                },
                {
                  value: 3,
                  label: "Fixed Risk",
                },
                {
                  value: 1,
                  label: "None",
                },
              ]}
            />
          </div>
        </div>
        <div className="tradeMimicBlueContainer">
          <div>
            <div className="userinputFieldContainer">
              <div className="accountsyncSubHeading">Trade Volume:</div>
              <div>
                <Input
                  placeholder="Type Here in the box"
                  className="masterAccountSyncInputStyle"
                  name="trade_volume"
                  value={syncAccount.trade_volume}
                  onChange={handleOnChange}
                  disabled={tradeVolumeEnable}
                  type="number"
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
            <div className="listAssestsStyle">
              Example: If 'Trade Volume' is set to 0.02 lots and the 'Subscriber
              Multiplier' field is set to '3', then all trades on the subscriber
              side will be equal to 0.06 lots (0.02 x 3 = 0.06 lots)
            </div>
          </div>

          <div>
            <div className="userinputFieldContainer">
              <div className="accountsyncSubHeading">Trade Risk(%)</div>
              <div>
                <Input
                  placeholder="Type Here in the box"
                  className="masterAccountSyncInputStyle"
                  name="risk_fraction"
                  value={syncAccount.risk_fraction}
                  onChange={handleOnChange}
                  disabled={tradeRiskEnable}
                  type="number"
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
            <div className="listAssestsStyle">
              Example: if 'Trade Risk' is set to 0.1 or 10% and the 'Subscriber
              Multiplier' field is set to '3' then as long as the strategy uses
              a stop loss, all trades on the subscriber side will risk 30% of
              account balance per trade (3x 10%)
            </div>
          </div>
        </div>

        <div className="accountSyncContainerStyle">
          <div className="accountsyncSubHeading">
            Subscriber Account Multiplier:
          </div>
          <div>
            <Input
              placeholder="01"
              className="masterAccountSyncInputStyle"
              name="user_account_multiplier"
              value={syncAccount.user_strategy.user_account_multiplier}
              onChange={handleSubscriberOnChange}
              type="number"
              onInput={handleNumericInput}
            />
          </div>
        </div>

        <div className="pendingOrderContainer">
          <div className="pendingOrderContainerStyle ">
            <div className="pendingHeadingStyle">Copy Pending Orders:</div>
            <div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                className="custom-select-accountsync"
                name="copy_pending_orders"
                onChange={(value) => {
                  handleSelectSubscriberOnChange(value, "copy_pending_orders");
                }}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div
            className="pendingOrderContainerStyle "
            style={{ paddingLeft: "1%", paddingRight: "1%" }}
          >
            <div className="pendingHeadingStyle">
              Disable Stop Loss Copying:
            </div>
            <div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                className="custom-select-accountsync"
                name="disable_stop_loss"
                onChange={(value) => {
                  handleSelectSubscriberOnChange(value, "disable_stop_loss");
                }}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div className="pendingOrderContainerStyle ">
            <div className="pendingHeadingStyle">
              Disable Take Profit Copying:
            </div>
            <div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                className="custom-select-accountsync"
                name="disable_take_profit"
                onChange={(value) => {
                  handleSelectSubscriberOnChange(value, "disable_take_profit");
                }}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="pendingOrderContainer">
          <div className="pendingOrderContainerStyle ">
            <div className="pendingHeadingStyle">Min Trade Volume:</div>
            <div>
              <div>
                <Input
                  placeholder="--"
                  className="masterAccountSyncInputStyle"
                  name="min_trade_volume"
                  value={syncAccount.user_strategy.min_trade_volume}
                  onChange={handleSubscriberOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          </div>
          <div
            className="pendingOrderContainerStyle "
            style={{ paddingLeft: "1%", paddingRight: "1%" }}
          >
            <div className="pendingHeadingStyle">Max Trade Volume:</div>
            <div>
              <div>
                <Input
                  placeholder="--"
                  className="masterAccountSyncInputStyle"
                  name="max_trade_volume"
                  value={syncAccount.user_strategy.max_trade_volume}
                  onChange={handleSubscriberOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          </div>
          <div className="pendingOrderContainerStyle ">
            <div className="pendingHeadingStyle">Reverse Trades</div>
            <div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                className="custom-select-accountsync"
                name="reverse_trades"
                onChange={(value) => {
                  handleSelectSubscriberOnChange(value, "reverse_trades");
                }}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="minmaxContainer">
          <div className="maxminContainerStyle ">
            <div className="pendingHeadingStyle">Slippage (Points):</div>
            <div>
              <div>
                <Input
                  placeholder="--"
                  className="masterAccountSyncInputStyle"
                  name="slippage"
                  value={syncAccount.user_strategy.slippage}
                  onChange={handleSubscriberOnChange}
                  onInput={handleIntegerInput}
                />
                <div className="listAssestsStyle">
                  *This feature is disabled as the connected master and
                  Subscriber accounts are not aligned to the same Broker server.
                </div>
              </div>
            </div>
          </div>
          <div
            className="maxminContainerStyle "
            style={{ paddingLeft: "1%", paddingRight: "1%" }}
          >
            <div className="pendingHeadingStyle">Select Margin Limit(%):</div>
            <div>
              <div>
                <Input
                  placeholder="Select"
                  className="masterAccountSyncInputStyle"
                  name="set_margin_criteria"
                  onChange={handleSubscriberOnChange}
                  type="decimal"
                  onInput={handleNumericInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="subscriberMultiplierContainerStyle">
          <div className="subscriberMultiplierFieldContainer">
            <div className="pendingHeadingStyle">Exclude Symbol</div>
            <div>
              <Input
                placeholder="01"
                className="masterAccountSyncInputStyle"
                name="excluded_symbols"
                value={syncAccount.user_strategy.excluded_symbols}
                onChange={handleSubscriberOnChange}
              />
            </div>
            <div className="listAssestsStyle">
              *Enter a list of assets separated by commas as per the following
              example: VIX, USDX, HK50
            </div>
          </div>
          <div className="subscriberMultiplierFieldContainer">
            <div className="pendingHeadingStyle">Open Trade Instructions:</div>
            <div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select"
                className="custom-select-accountsync"
                name="close_instructions"
                onChange={(value) => {
                  handleSelectSubscriberOnChange(value, "close_instructions");
                }}
                options={[
                  {
                    value: "preserve",
                    label: "  Leave open order positions unmanaged ",
                  },
                  {
                    value: "close-gracefully-by-position",
                    label:
                      "Close all open order positions gradually by position",
                  },
                  {
                    value: "close-gracefully-by-symbol",
                    label: "Close all open order positions gradually by symbol",
                  },
                  {
                    value: "close-immediately",
                    label: "Close all open order positions immediately",
                  },
                ]}
              />
              <div className="listAssestsStyle">
                *On Strategy or Account Removal*
              </div>
            </div>
          </div>
        </div>
        <div className="createMimicRadioContainerStyle">
          <div className="traderadioContainerStyle">
            <div className="AccoutTypeStyle">Set a Max Risk Threshold:</div>

            <div className="AccoutTypeRadioStyle">
              <Radio.Group
                name="max_risk"
                onChange={(value) => {
                  value.target.value === "yes"
                    ? setMaxRisk(true)
                    : setMaxRisk(false);
                }}
                defaultValue={"no"}
              >
                <Space direction="horizontal">
                  <div>
                    <Radio value={"yes"}>Yes</Radio>
                  </div>
                  <div>
                    <Radio value={"no"}>No</Radio>
                  </div>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div
          className="mimicTradenoteContainerStyle"
          style={{ flexDirection: "column" }}
        >
          <div>
            *Please note that this feature cannot be selected if stop loss
            copying has been disabled. If the strategy or trades being copied do
            not use stop losses, the trade feature will be redundant as the risk
            % threshold cannot be calculated.
          </div>
          <div className="maxRiskStyle">
            <div className="pendingHeadingStyle">Max Risk (%)</div>
            <div>
              <div style={{ paddingLeft: "2%" }}>
                <Input
                  placeholder="01"
                  className="masterAccountSyncInputStyle"
                  name="max_risk"
                  value={syncAccount.user_strategy.max_risk}
                  onChange={handleSubscriberOnChange}
                  disabled={!maxRisk}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="createMimicRadioContainerStyle">
          <div className="traderadioContainerStyle">
            <div className="AccoutTypeStyle">Set a Drawdown Threshold:</div>

            <div className="AccoutTypeRadioStyle">
              <Radio.Group
                name="close_all_existing_when_drawdown"
                value={
                  syncAccount.user_strategy.close_all_existing_when_drawdown
                }
                onChange={(value) => {
                  value.target.value === true
                    ? setDrawDownThreshold(true)
                    : setDrawDownThreshold(false);
                  handleSelectSubscriberOnChange(
                    value.target.value,
                    "close_all_existing_when_drawdown"
                  );
                }}
              >
                <Space direction="horizontal">
                  <div>
                    <Radio value={true}>Yes</Radio>
                  </div>
                  <div>
                    <Radio value={false}>No</Radio>
                  </div>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="tradeMimicBlueContainer">
          <div className="pendingOrderContainer">
            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">
                Disable all copy trading:{" "}
              </div>
              <div>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  className="custom-select-accountsync"
                  name="dont_copy_trades_when_drawdown"
                  onChange={(value) => {
                    handleSelectSubscriberOnChange(
                      value,
                      "dont_copy_trades_when_drawdown"
                    );
                  }}
                  options={[
                    {
                      value: true,
                      label: "Yes",
                    },
                    {
                      value: false,
                      label: "No",
                    },
                  ]}
                  disabled={!drawDownThreshold}
                />
              </div>
            </div>
            <div
              className="pendingOrderContainerStyle "
              style={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
              <div className="pendingHeadingStyle">DD Threshold Options:</div>
              <div>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  className="custom-select-accountsync"
                  name="drawdown_threshold"
                  onChange={(value) => {
                    handleSelectSubscriberOnChange(value, "drawdown_threshold");
                  }}
                  options={[
                    {
                      value: "daily",
                      label: "DAILY",
                    },
                    {
                      value: "monthly",
                      label: "MONTHLY",
                    },
                    {
                      value: "yearly",
                      label: "YEARLY",
                    },
                    {
                      value: "lifetime",
                      label: "MAX_DRAWDOWN",
                    },
                  ]}
                  disabled={!drawDownThreshold}
                />
              </div>
            </div>
            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">Drawdown (%):</div>
              <div>
                <div>
                  <Input
                    placeholder="01"
                    className="masterAccountSyncInputStyle"
                    name="drawdown"
                    value={syncAccount.user_strategy.drawdown}
                    onChange={handleSubscriberOnChange}
                    disabled={!drawDownThreshold}
                    onInput={handleIntegerInput}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pendingOrderContainer">
            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">Drawdown (Currency):</div>
              <div>
                <Input
                  placeholder="01"
                  className="masterAccountSyncInputStyle"
                  name="drawdown_currency"
                  value={syncAccount.user_strategy.drawdown_currency}
                  onChange={handleSubscriberOnChange}
                  disabled={!drawDownThreshold}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>

            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">Apply To:</div>
              <div>
                <div>
                  <Select
                    style={{
                      width: "100%",
                      paddingLeft: "3%",
                      paddingRight: "1%",
                    }}
                    placeholder="Select"
                    disabled={!drawDownThreshold}
                    className="custom-select-accountsync"
                    name="drawdown_apply_to"
                    onChange={(value) => {
                      handleSelectSubscriberOnChange(
                        value,
                        "drawdown_apply_to"
                      );
                    }}
                    options={[
                      {
                        value: "balance",
                        label: "BALANCE",
                      },
                      {
                        value: "equity",
                        label: "EQUITY",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="pendingOrderContainerStyle " />
          </div>
        </div>
        <div className="createMimicRadioContainerStyle">
          <div className="traderadioContainerStyle">
            <div className="AccoutTypeStyle">Calculate Trade Commissions:</div>

            <div className="AccoutTypeRadioStyle">
              <Radio.Group
                onChange={(value) => {
                  value.target.value === "yes"
                    ? setCalculateTrade(true)
                    : setCalculateTrade(false);
                }}
                name="account_type"
                defaultValue={"no"}
              >
                <Space direction="horizontal">
                  <div>
                    <Radio value={"yes"}>Yes</Radio>
                  </div>
                  <div>
                    <Radio value={"no"}>No</Radio>
                  </div>
                </Space>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="tradeMimicBlueContainer">
          <div className="pendingOrderContainer">
            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">Type:</div>
              <div>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  className="custom-select-accountsync"
                  name="billing_type"
                  onChange={(value) => {
                    handleSelectSubscriberOnChange(value, "billing_type");
                  }}
                  options={[
                    {
                      value: "flat-fee",
                      label: "FLAT FEE",
                    },
                    {
                      value: "lots-traded",
                      label: "LOTS TRADED",
                    },
                    {
                      value: "lots-won",
                      label: "LOTS WON",
                    },
                    {
                      value: "amount-won",
                      label: "AMOUNT WON",
                    },
                    {
                      value: "high-water-mark",
                      label: "HIGH WATER MARK",
                    },
                  ]}
                  disabled={!calculateTrade}
                />
              </div>
            </div>
            <div
              className="pendingOrderContainerStyle "
              style={{ paddingLeft: "1%", paddingRight: "1%" }}
            >
              <div className="pendingHeadingStyle">Billing Period:</div>
              <div>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  className="custom-select-accountsync"
                  name="billing_period"
                  onChange={(value) => {
                    handleSelectSubscriberOnChange(value, "billing_period");
                  }}
                  options={[
                    {
                      value: "week",
                      label: "WEEK",
                    },
                    {
                      value: "month",
                      label: "MONTH",
                    },
                    {
                      value: "quarter",
                      label: "QUARTER",
                    },
                  ]}
                  disabled={!calculateTrade}
                />
              </div>
            </div>
            <div className="pendingOrderContainerStyle ">
              <div className="pendingHeadingStyle">Commission Rate(%):</div>
              <div>
                <div>
                  <Input
                    placeholder="01"
                    className="masterAccountSyncInputStyle"
                    name="commission_rate"
                    value={syncAccount.user_strategy.commission_rate}
                    onChange={handleSubscriberOnChange}
                    disabled={!calculateTrade}
                    onInput={handleNumericInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscriberTradeMimic;
