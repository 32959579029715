import { Input, Spin } from "antd";
import { LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import "./AsyncSymbolStyle.scss";
import { useState, useEffect } from "react";

const AsyncSymbol = ({
  accountSyncData,
  handleOnChange,
  excludeSymbol,
  updateSymbol,
  isExcludeLoading,
  isLoading,
  UpdatedAccountSync,
  setShowSymbol,
}) => {
  const [array, setArray] = useState([]);
  const excludedSymbols = accountSyncData?.excluded_symbols ?? "";

  useEffect(() => {
    if (excludedSymbols.includes(",")) {
      setArray(excludedSymbols.split(",").map((item) => item.trim()));
    } else if (excludedSymbols === "") {
      setArray([]);
      setShowSymbol(true);
    } else {
      setArray([accountSyncData?.excluded_symbols]);
    }
  }, [accountSyncData]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      <div className="GeneralSettingMainContainer">
        <div className="SymbolSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">Exclude Symbol </div>
        </div>
        <div className="asyncSymbolsInputFieldContainer">
          <div style={{ width: "500px" }}>
            <div className="AccoutTypeStyle">Exclude Symbol</div>
            <div className="excludeSymbolContainer">
              <div>
                <Input
                  placeholder="Type in the box"
                  className="AccountRiskInputStyle"
                  name="updateSymbol"
                  value={updateSymbol}
                  onChange={handleOnChange}
                />
                <div>
                  <div className="listAssestsStyle">
                    *Enter a list of assets separated by commas as per the
                    following example: VIX, USDX, HK50
                  </div>
                </div>
              </div>
              <div>
                <MetaSyncButton
                  text="Exclude"
                  className="excludeButtonStyle"
                  onClick={UpdatedAccountSync}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <>
          <div className="SymbolSettingHeadingContainer">
            <div className="createMasterAccoutTextStyle">Excluded Symbols </div>
          </div>
          <div className="asyncSynbolnputFieldContainer">
            <div className="symbolFieldStyle">
              {isExcludeLoading ? (
                <Spin
                  indicator={antIcon}
                  size="large"
                  centered
                  className="spinSymbolClass"
                />
              ) : (
                <>
                  {array.map((item, i) => {
                    return (
                      <div className="symbolContainerStyle" key={i}>
                        <span className="symbolTextStyle">{item}</span>
                        <CloseCircleFilled
                          style={{ color: "#1C8BFE" }}
                          onClick={() => excludeSymbol(i, "excluded_symbols")}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  );
};
export default AsyncSymbol;
