import { Navigate } from "react-router-dom";
import { Navbar, Sidebar } from "component/mainComponent";
import { getToken } from "component/utilis/localStorage";
import { useState } from "react";
import React from "react";

const PrivateRoute = ({ children, sidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateUserDataNav, setUpdateUserDataNav] = useState(false);

  return getToken() && sidebar ? (
    <>
      <Sidebar isOpen={isOpen}>
        <>
          <Navbar
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            updateUserDataNav={updateUserDataNav}
          />
          {React.Children.map(
            children,
            (child) =>
              React.cloneElement(child, {
                updateUserDataNav,
                setUpdateUserDataNav,
              })
          )}
        </>
      </Sidebar>
    </>
  ) : getToken() && !sidebar ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
