import { Select } from "antd";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";

const AsyncStopAndLimit = ({
  accountSyncData,
  handleSyncSelectChange,
  UpdatedAccountSync,
  updatedAccountSyncData,
  isLoading,
}) => {
  return (
    <>
      <div className="GeneralSettingMainContainer">
        <div className="RiskSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">Stop & Limits </div>
        </div>

        <div className="asyncRiskInputFieldContainer">
          <div className="pendingOrderContainerStyle ">
            <div className="AccoutTypeStyle">Disable Stop Loss Copying</div>
            <div>
              <Select
                style={{
                  width: "100%",
                  margin: "2% 0 2% 0",
                }}
                placeholder="Select"
                className="custom-select-broker"
                name="disable_stop_loss_copying"
                onChange={(value) => {
                  handleSyncSelectChange(value, "disable_stop_loss_copying");
                }}
                value={accountSyncData?.disable_stop_loss_copying}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div className="pendingOrderContainerStyle ">
            <div className="AccoutTypeStyle">Disable Take Profit Copying</div>
            <div>
              <Select
                style={{
                  width: "100%",
                  margin: "2% 0 2% 0",
                }}
                placeholder="Select"
                className="custom-select-broker"
                name="disable_take_profit_copying"
                value={accountSyncData?.disable_take_profit_copying}
                onChange={(value) => {
                  handleSyncSelectChange(value, "disable_take_profit_copying");
                }}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div className="pendingOrderContainerStyle ">
            <div className="AccoutTypeStyle">Copy Pending Orders</div>
            <div>
              <Select
                style={{
                  width: "100%",
                  margin: "2% 0 2% 0",
                }}
                placeholder="Select"
                className="custom-select-broker"
                name="dont_copy_trades_when_drawdown"
                onChange={(value) => {
                  handleSyncSelectChange(
                    value,
                    "dont_copy_trades_when_drawdown"
                  );
                }}
                value={accountSyncData?.dont_copy_trades_when_drawdown}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <MetaSyncButton
            text="Update"
            onClick={UpdatedAccountSync}
            disabled={!Object.entries(updatedAccountSyncData).length > 0}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
export default AsyncStopAndLimit;
