import { useState, useEffect } from "react";
import { DownOutlined, UpOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Modal, Table, Select, message, Spin } from "antd";
import { LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import checkedGreen from "assets/images/checkedGreen.svg";
import deleteIcon from "assets/images/tableDeleteIcon.svg";
import listIcon from "assets/images/tableListIcon.svg";
import informationicon from "assets/images/information-button.svg";
import AccountSyncRevamp from "../AccountSyncRevamp";
import { useNavigate } from "react-router-dom";
import {
  getApiWithAuth,
  deleteApiWithAuth,
  patchApiWithAuth,
} from "component/utilis/apiWrapper";
import "./AccountSyncStyle.scss";

const AccountSync = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [SyncAccountData, setSyncAccountData] = useState({});
  const [masterAccounts, setMasterAccounts] = useState([]);
  const [strategyAccounts, setStrategyAccounts] = useState([]);
  const [userAccounts, setUserAccounts] = useState([]);
  const [isCreated, setIsCreated] = useState(false);
  const [deleteMasterAccountId, setDeleteMasterAccountId] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeys2, setExpandedRowKeys2] = useState([]);
  const [disconnectId, setDisconnectId] = useState();
  const [closeInstruction, setCloseInstruction] = useState("");
  const [deleted, setDeleted] = useState(false);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const getSyncAccounts = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth("accounts/get-sync-account");
    setIsLoading(false);

    if (!response.success) {
      message.error(`${response.message.data.error}`);

      return;
    }
    setSyncAccountData(response.data.connected_accounts);
  };
  useEffect(() => {
    getSyncAccounts();
  }, [isCreated]);
  useEffect(() => {
    handleUserData(false, 0);
  }, [SyncAccountData, deleted]);
  useEffect(() => {
    handleUserData(false, 0);
  }, [deleted]);

  const disconnectAccount = async () => {
    setIsLoading(true);
    const response = await patchApiWithAuth(
      `accounts/${disconnectId}/disconnect-account/?close_instructions=${closeInstruction}`
    );
    setIsLoading(false);
    getSyncAccounts();
  };

  const handleStrategyData = (expanded, i) => {
    if (expandedRowKeys2.length > 0 && expanded) {
      setExpandedRowKeys2([]);
    }
    if (expanded) {
      setExpandedRowKeys2([i.key]);
    } else {
      setExpandedRowKeys2([]);
    }
    if (SyncAccountData && Array.isArray(SyncAccountData)) {
      const data2 = SyncAccountData?.flatMap((item) => {
        if (i.masterid == item.master_account.account_number) {
          return item.strategies.map((strategy, index) => ({
            key: `${item.master_account.id}_${strategy.strategy_name}`,
            masterid: item.master_account.account_number,
            masteraccount: item.master_account.account_name,
            strategyname: strategy.strategy_name,
            synchronise: (
              <>
                <MetaSyncButton text="Disconnected" onClick={showModal} />
              </>
            ),

            masterstrategyconfiguration: (
              <>
                <div>
                  <img
                    src={listIcon}
                    width={20}
                    height={20}
                    style={{
                      cursor: "pointer",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                    onClick={() =>
                      navigate("/accountsyncSetting", {
                        state: {
                          id: strategy.id,
                          masterId: item.master_account.id,
                          strategy: strategy,
                        },
                      })
                    }
                  />
                  <img
                    src={deleteIcon}
                    width={20}
                    height={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => showDeleteModal(strategy.id)}
                  />
                </div>
              </>
            ),
          }));
        }
        return [];
      });
      setStrategyAccounts(data2);
    }
  };
  const reconnectStrategy = async (id) => {
    setIsLoading(false);
    const response = await patchApiWithAuth(`accounts/${id}/reconnect/`);
    setIsLoading(true);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Account Reconnected");
    getSyncAccounts();
  };

  const handleUserData = (expanded, i) => {
    if (expandedRowKeys.length > 0 && expanded) {
      setExpandedRowKeys([]);
    }

    if (expanded) {
      setExpandedRowKeys([i.key]);
    } else {
      setExpandedRowKeys([]);
    }

    const parentKey = i.parentKey;
    if (SyncAccountData && Array.isArray(SyncAccountData)) {
      const data3 = SyncAccountData.flatMap((item) => {
        return item.strategies.flatMap((strategy) => {
          if (strategy.strategy_name === i.strategyname) {
            return strategy.synced_accounts?.map((sync) => ({
              key: `${item.master_account.id}_${strategy.strategy_name}_${sync.id}`,
              masterid: item.master_account.account_number,
              masteraccount: item.master_account.account_name,
              strategyname: strategy.strategy_name,
              masterstrategyconfiguration:
                strategy.master_strategy_configuration,
              subscriberaccount: sync.user_account.account_number,
              synchronise: (
                <>
                  {sync.connection_status === "Connected" ? (
                    <MetaSyncButton
                      text="Disconnect"
                      onClick={() => showModal(sync.id)}
                      className="disconectButtonStyle"
                    />
                  ) : (
                    <MetaSyncButton
                      text="Connect"
                      onClick={() => {
                        reconnectStrategy(sync.id);
                      }}
                      className="disconectButtonStyle"
                    />
                  )}
                </>
              ),

              connectionstatus: (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {sync.connection_status === "Connected" ? (
                        <img
                          src={checkedGreen}
                          width={15}
                          height={15}
                          style={{
                            paddingTop: "9px",
                            paddingRight: "5px",
                          }}
                        />
                      ) : (
                        <div style={{ paddingRight: "5px" }}>
                          <CloseCircleFilled style={{ color: "red" }} />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        color:
                          sync.connection_status === "Connected"
                            ? "#61DE7E"
                            : "red",
                        paddingTop: "3px",
                      }}
                    >
                      {sync.connection_status}
                    </div>
                  </div>
                </>
              ),
            }));
          } else {
            return [];
          }
        });
      });

      setUserAccounts(data3);
    }
  };

  useEffect(() => {
    if (SyncAccountData && Array.isArray(SyncAccountData)) {
      const data = SyncAccountData.map((item, index) => ({
        key: index,
        masterid: item.master_account.account_number,
        masteraccount: item.master_account.account_name,
        master_account: item.master_account,
      }));
      setMasterAccounts(data);
    }
  }, [SyncAccountData]);

  const showModal = (id) => {
    setIsModalOpen(true);
    setDisconnectId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    showConfirmModal();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showDeleteModal = (id) => {
    setIsModalDeleteOpen(true);
    setDeleteMasterAccountId(id);
  };
  const handleDeleteOk = () => {
    deleteMasterStrategy();
  };
  const handleDeleteCancel = () => {
    setIsModalDeleteOpen(false);
  };
  const showConfirmModal = () => {
    setIsModalConfirmOpen(true);
  };
  const handleConfirmOk = () => {
    setIsModalConfirmOpen(false);
    disconnectAccount();
  };
  const handleConfirmCancel = () => {
    setIsModalConfirmOpen(false);
  };
  const deleteMasterStrategy = async () => {
    setIsModalDeleteOpen(false);

    setIsLoading(true);
    const response = await deleteApiWithAuth(
      `accounts/${deleteMasterAccountId}/delete-master-strategy`
    );
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setDeleted(true);
    getSyncAccounts();
    message.success("Master Strategy deleted successfully");
    setExpandedRowKeys([]);
    setExpandedRowKeys2([]);
  };
  const columns = [
    {
      title: "Master Account Number",
      dataIndex: "masterid",
      key: "masterid",
    },
    Table.EXPAND_COLUMN,
    {
      title: "Master Account",
      dataIndex: "masteraccount",
      key: "masteraccount",
    },
  ];
  const columns2 = [
    {
      title: "Strategy Name",
      dataIndex: "strategyname",
      key: "strategyname",
    },
    {
      title: "Master Strategy Configuration",
      dataIndex: "masterstrategyconfiguration",
      key: "masterstrategyconfiguration",
    },
  ];
  const columns3 = [
    {
      title: "Subscriber Account",
      dataIndex: "subscriberaccount",
      key: "subscriberaccount",
    },
    {
      title: "Connection Status",
      dataIndex: "connectionstatus",
      key: "connectionstatus",
    },

    {
      title: "Synchronise",
      dataIndex: "synchronise",
      key: "synchronise",
    },
  ];
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  return (
    <>
      <div className="marketplaceListStyle">
        <div className="masterHeadingContainerStyle">
          <div className="backMasterContaierStyle">
            {isCreated ? (
              <div>
                <MetaSyncButton
                  text={
                    <>
                      <ArrowLeftOutlined />
                    </>
                  }
                  onClick={() => setIsCreated(false)}
                  className="backButtonStyle"
                />
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="masterAccountHeading">
                Account Sync
                <sup>
                  <img src={informationicon} className="titleInformationIcon" />
                </sup>
              </div>
              <div className="masterLinkStyle">
                <u className="clickhereStyle">Click here </u>&nbsp; to learn
                About Account Syncing
              </div>
            </div>
          </div>
          <div>
            {!isCreated ? (
              <div>
                <MetaSyncButton
                  text="Connect Accounts"
                  onClick={() => setIsCreated(true)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!isCreated ? (
          isLoading ? (
            <div className="tableListBoxStyle">
              <Spin
                indicator={antIcon}
                size="large"
                centered
                className="spinClass"
              />
            </div>
          ) : (
            <div className="tableListBoxStyle">
              <Table
                dataSource={masterAccounts}
                columns={columns}
                className="expandRowStyle"
                expandedRowKeys={expandedRowKeys2}
                pagination={{
                  itemRender: itemRender,
                }}
                expandable={{
                  onExpand: (record, index) => {
                    handleStrategyData(record, index);
                  },
                  rowExpandable: (record) => true,
                  expandedRowRender: (record, index) => {
                    return (
                      <Table
                        dataSource={strategyAccounts}
                        columns={columns2}
                        className="expandInnerRowStyle"
                        pagination={false}
                        width={100}
                        expandedRowKeys={expandedRowKeys}
                        expandable={{
                          onExpand: (record, index) => {
                            handleUserData(record, index);
                          },
                          rowExpandable: (record) => true,

                          expandedRowRender: (record) => {
                            return (
                              <Table
                                dataSource={userAccounts}
                                columns={columns3}
                                className="expandInnerRowStyle3"
                                pagination={false}
                              />
                            );
                          },
                          expandIcon: ({ expanded, onExpand, record }) =>
                            !expanded ? (
                              <DownOutlined
                                onClick={(e) => onExpand(record, e)}
                              />
                            ) : (
                              <UpOutlined
                                onClick={(e) => onExpand(record, e)}
                              />
                            ),
                        }}
                      />
                    );
                  },
                  expandIcon: ({ expanded, onExpand, record }) =>
                    !expanded ? (
                      <DownOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <UpOutlined onClick={(e) => onExpand(record, e)} />
                    ),
                }}
              />
            </div>
          )
        ) : (
          <div className="tableBoxStyle">
            <AccountSyncRevamp setIsCreated={setIsCreated} />
          </div>
        )}
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          okText={"Yes"}
          cancelText={"No"}
          width={860}
          className="centered-modal-footer"
        >
          <div className="accountSyncmodalQuestionStyle">
            Are you sure you want to disconnect this account?
          </div>
        </Modal>
        <Modal
          open={isModalDeleteOpen}
          onOk={handleDeleteOk}
          onCancel={handleDeleteCancel}
          centered={true}
          okText={"Yes"}
          cancelText={"No"}
          width={860}
          className="centered-modal-footer"
        >
          <div className="accountSyncmodalQuestionStyle">
            Are you sure you want to delete this strategy configuration?
          </div>
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            *By deleting the Master Stratetgy configuration,
            <span style={{ fontWeight: "700" }}>
              all Subscriber accounts will be disconnected and removed from the
              Account Connections dashboard.
            </span>
            Accounts will remain deployed and editable in the Subscriber
            Accounts dashboard but users will need to connect them to a new
            Master Account in the Account Sync section.
          </div>
          <div style={{ textAlign: "center", paddingTop: "15px" }}>
            Please note that this strategy has
            <span style={{ fontWeight: "700" }}>
              {" "}
              ‘Open Trade Instructions - On Strategy or Account Removal’ set to
              ‘Unmanaged’ (Default).
            </span>{" "}
            If you delete this strategy, all connected subscriber accounts that
            haven't configured{" "}
            <span style={{ fontWeight: "700" }}>
              'Open Trade Instructions'
            </span>{" "}
            at the account level{" "}
            <span style={{ fontWeight: "700" }}>
              will remain open and unmanaged following deletion.
            </span>
            Please make the appropriate changes to your configuration settings
            at the strategy level if you wish to close your Subscriber accounts
            trades at the point of strategy deletion.
          </div>
        </Modal>
        <Modal
          open={isModalConfirmOpen}
          onOk={handleConfirmOk}
          onCancel={handleConfirmCancel}
          centered={true}
          okText={"Confirm"}
          cancelText={""}
          width={600}
          className="centered-modal-footer"
        >
          <div
            className="accountSyncmodalQuestionStyle"
            style={{ paddingTop: "3%" }}
          >
            Before disconnecting the account, confirm how open trades should be
            managed.
          </div>
          <div style={{ height: "100px", paddingTop: "5%" }}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Select"
              className="custom-select-accountsync"
              name="closeInstruction"
              onChange={(value) => setCloseInstruction(value)}
              options={[
                {
                  value: "preserve",
                  label: "  Leave open order positions unmanaged ",
                },
                {
                  value: "close-gracefully-by-position",
                  label: "Close all open order positions gradually by position",
                },
                {
                  value: "close-gracefully-by-symbol",
                  label: "Close all open order positions gradually by symbol",
                },
                {
                  value: "close-immediately",
                  label: "Close all open order positions immediately",
                },
              ]}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};
export default AccountSync;
