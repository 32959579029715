import { useEffect, useState } from "react";
import { MetaSyncButton } from "component/commonCompnent";
import deleteIcon from "assets/images/tableDeleteIcon.svg";
import { Input, Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./AsyncMapSymbolStyle.scss";

const AsyncMapSymbol = ({
  accountSyncData,
  handleOnChange,
  symbolMapping,
  UpdatedAccountSync,
  deleteMapSymbol,
  updatedAccountSyncData,
  isDeleteLoading,
  isLoading,
}) => {
  const [tableData, setTableData] = useState([]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const columns = [
    {
      title: <span className="customColumnHeaderStyle">From</span>,
      dataIndex: "from",
      key: "from",
      width: "160px",
    },
    {
      title: <span className="customColumnHeaderStyle">To</span>,
      dataIndex: "to",
      key: "to",
      width: "160px",
    },
    {
      title: <span className="customColumnHeaderStyle">Action</span>,
      dataIndex: "delete",
      key: "delete",
      width: "160px",
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };

  useEffect(() => {
    if (accountSyncData) {
      const data = accountSyncData?.symbol_mappings.map((item) => ({
        from: <span className="customRowStyle">{item?.from_symbol}</span>,
        to: <span className="customRowStyle">{item?.to_symbol}</span>,
        delete: (
          <>
            <img
              src={deleteIcon}
              width={15}
              height={15}
              onClick={() =>
                deleteMapSymbol(item?.from_symbol, item?.to_symbol)
              }
              style={{ cursor: "pointer" }}
            />
          </>
        ),
      }));
      setTableData(data);
    }
  }, [accountSyncData]);

  return (
    <>
      <div className="GeneralSettingMainContainer">
        <div className="SymbolSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">Symbol Mapping </div>
        </div>
        <div className="asyncMapInputFieldContainer">
          <div>
            <div className="mapTableTitleContainerStyle">From</div>
            <div>
              <Input
                placeholder="Type in the box"
                className="AccountRiskInputStyle"
                name="from_symbol"
                value={symbolMapping.from_symbol}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div>
            <div className="mapTableTitleContainerStyle">To</div>
            <div>
              <Input
                placeholder="Type in the box"
                className="AccountRiskInputStyle"
                name="to_symbol"
                value={symbolMapping.to_symbol}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div>
            <MetaSyncButton
              text="Add"
              className="mapAddButtonStyle"
              onClick={UpdatedAccountSync}
              disabled={!Object.entries(updatedAccountSyncData).length > 0}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="SymbolSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">Mapped Symbols </div>
        </div>
        <div className="mapTableContainer">
          {isDeleteLoading ? (
            <Spin
              indicator={antIcon}
              size="large"
              centered
              className="spinSymbolClass"
            />
          ) : (
            <Table
              columns={columns}
              dataSource={tableData}
              className="tradeTable"
              scroll={{
                y: 240,
              }}
              style={{ width: "500px" }}
              pagination={{
                pageSize: 5,
                position: ["bottomRight"],
                size: "default",
                itemRender: itemRender,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default AsyncMapSymbol;
