import "./AsyncRiskSettingStyle.scss";
import { Input, Select } from "antd";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import {
  handleNumericInput,
  handleIntegerInput,
} from "component/utilis/constant";

const AsyncRiskSetting = ({
  accountSyncData,
  handleSyncSelectChange,
  handleOnChange,
  UpdatedAccountSync,
  tradeVolumeEnable,
  tradeRiskEnable,
  isLoading,
  updatedAccountSyncData,
}) => {
  return (
    <>
      <div className="GeneralSettingMainContainer">
        <div className="RiskSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">Risk Settings </div>
        </div>
        <div className="asyncRiskInputFieldContainer">
          <div className="accountSyncContainerStyle">
            <div className="AccoutTypeStyle">Meta Sync Risk Mode</div>
            <div>
              <Select
                style={{
                  width: "100%",
                  margin: "2% 0 2% 0",
                }}
                placeholder="Select"
                className="custom-select-broker"
                onChange={(value) => {
                  handleSyncSelectChange(value, "mode");
                }}
                value={accountSyncData.mode}
                options={[
                  {
                    value: "contractSize",
                    label: "Contract Size",
                  },
                  {
                    value: "balance",
                    label: "Balance",
                  },
                  {
                    value: "fixedVolume",
                    label: "Fixed Volume",
                  },
                  {
                    value: "fixedRisk",
                    label: "Fixed Risk",
                  },
                  {
                    value: "none",
                    label: "None",
                  },
                ]}
              />
            </div>
          </div>
          {!tradeVolumeEnable ? (
            <div>
              <div className="AccoutTypeStyle">Trade Volume:</div>
              <div>
                <Input
                  placeholder="Type Here in the box"
                  className="AccountSyncRevampInputStyle"
                  name="trade_volume"
                  value={accountSyncData.trade_volume}
                  onChange={handleOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {!tradeRiskEnable ? (
            <div>
              <div className="AccoutTypeStyle">Trade Risk(%)</div>
              <div>
                <Input
                  placeholder="Type Here in the box"
                  className="AccountSyncRevampInputStyle"
                  name="risk_fraction"
                  value={accountSyncData.risk_fraction}
                  onChange={handleOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="pendingOrderContainerStyle ">
            <div className="AccoutTypeStyle">Reverse Trades</div>
            <div>
              <Select
                style={{
                  width: "100%",
                  margin: "2% 0 2% 0",
                }}
                placeholder="Select"
                className="custom-select-broker"
                name="reverse_trades"
                onChange={(value) => {
                  handleSyncSelectChange(value, "reverse_trades");
                }}
                value={accountSyncData.reverse_trades}
                options={[
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <div>
            <div className="AccoutTypeStyle">Multiplier:</div>
            <div>
              <Input
                placeholder="01"
                className="AccountRiskInputStyle"
                name="multiplier"
                value={accountSyncData?.multiplier}
                onChange={handleOnChange}
                onInput={handleNumericInput}
                type="number"
              />
            </div>
          </div>

          <div className="pendingOrderContainerStyle ">
            <div className="AccoutTypeStyle">Min Trade Volume:</div>
            <div>
              <div>
                <Input
                  placeholder="--"
                  className="AccountRiskInputStyle"
                  name="min_trade_volume"
                  value={accountSyncData.min_trade_volume}
                  onChange={handleOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          </div>
          <div
            className="pendingOrderContainerStyle "
            style={{ paddingLeft: "1%", paddingRight: "1%" }}
          >
            <div className="AccoutTypeStyle">Max Trade Volume:</div>
            <div>
              <div>
                <Input
                  placeholder="--"
                  className="AccountRiskInputStyle"
                  name="max_trade_volume"
                  value={accountSyncData?.max_trade_volume}
                  onChange={handleOnChange}
                  onInput={handleIntegerInput}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="riskamanagmentUpdateButtonContainer">
          <MetaSyncButton
            text="Update"
            onClick={UpdatedAccountSync}
            disabled={!Object.entries(updatedAccountSyncData).length > 0}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
export default AsyncRiskSetting;
