import { useState } from "react";
import metaSyncIcon from "assets/images/MetaSyncIcon.svg";
import MetaSyncButton from "../metaSyncButton";
import "./HomePageNavbarStyle.scss";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const HomePageNavbar = () => {
  const [activeTab, setActiveTab] = useState(4);
  const { loginWithRedirect } = useAuth0();
  const handleClick = (index) => {
    setActiveTab(index);
  };
  const navigate = useNavigate();

  const handleSignup = () => {
    loginWithRedirect({ screen_hint: "signup" });
  };

  return (
    <>
      <nav style={{ padding: "2% 2% 1% 2%" }}>
        <div className="homeNavMainStyle">
          <div className="homeNavStyle">
            <div
              className={activeTab === 3 ? "activeNav" : "navbarContantStyle"}
              onClick={() => handleClick(3)}
            >
              <img src={metaSyncIcon} onClick={() => navigate("/")} />
            </div>
            <div
              className={activeTab === 0 ? "activeNav" : "navbarContantStyle"}
              onClick={() => handleClick(0)}
            >
              <div onClick={() => navigate("/pricing")}>Pricing</div>
            </div>
            <div
              className={activeTab === 1 ? "activeNav" : "navbarContantStyle"}
              onClick={() => handleClick(1)}
            >
              <div onClick={() => navigate("/faq")}>FAQ</div>
            </div>
            <div
              className={activeTab === 2 ? "activeNav" : "navbarContantStyle"}
              onClick={() => handleClick(2)}
            >
              <div onClick={() => navigate("/contact")}>Contact</div>
            </div>
          </div>
          <div className="homeNavButtonStyle">
            <div>
              <MetaSyncButton
                text="Get started"
                className="SignupNavButtonStyle"
                onClick={handleSignup}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HomePageNavbar;
