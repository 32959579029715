import { Input, Select, message } from "antd";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { useEffect, useState } from "react";
import { handleIntegerInput } from "component/utilis/constant";
import { getApiWithAuth, postApiWithAuth } from "component/utilis/apiWrapper";
import "./AccountSyncRevampStyle.scss";

const AccountSyncRevamp = ({ setIsCreated }) => {
  const [masterStrategies, setMasterStrategies] = useState([]);
  const [subscriberStrategies, setSubscriberStrategies] = useState([]);
  const [masterAccountId, setMasterAccountId] = useState();
  const [selectMasterStrategies, setSelectMasterStrategies] = useState([]);
  const [tradeVolumeEnable, setTradeVolumeEnable] = useState(true);
  const [tradeRiskEnable, setTradeRiskEnable] = useState(true);
  const [exsistingStrategyEnable, setExsistingStrategyEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [syncAccount, setSyncAccount] = useState({
    user_account_to_sync: 0,
    master_account_to_sync: 0,
    use_existing_master_startegy: "",
    copy_pending_orders: true,
    master_strategy: {
      risk_settings: 1,
      strategy_name: "",
      master_account_multiplier: "",
      tradeVolume: 0.0,
    },
  });

  const AccountSync = async () => {
    setIsLoading(true);
    const response = await postApiWithAuth(
      "accounts/sync-account/",
      syncAccount
    );
    setIsLoading(false);
    setIsCreated(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Accouts synced successfully");
  };

  const getMasterAccounts = async () => {
    const response = await getApiWithAuth("accounts/get-masters-dashboard");
    if (!response.success) {
      return;
    }
    const name = response?.data.map((item) => ({
      name: item.account_name,
      id: item.id,
    }));
    setMasterStrategies(name);
  };

  const getSubscriberAccounts = async () => {
    const response = await getApiWithAuth("accounts/get-users-dashboard");
    if (!response.success) {
      return;
    }
    const name = response?.data.map((item) => ({
      name: item.account_name,
      id: item.id,
    }));
    setSubscriberStrategies(name);
  };

  const getMasterStrategies = async () => {
    const response = await getApiWithAuth(
      `accounts/${masterAccountId}/get-master-strategies`
    );
    if (!response.success) {
      return;
    }
    const name = response?.data.map((item) => ({
      name: item.strategy_name,
      id: item.id,
    }));
    setSelectMasterStrategies(name);
  };
  useEffect(() => {
    if (masterAccountId) {
      getMasterStrategies();
    }
  }, [masterAccountId]);

  const handleSelectChange = (value, name) => {
    if (name === "risk_settings") {
      if (value == 2) {
        setTradeVolumeEnable(false);
        setTradeRiskEnable(true);
      } else if (value == 3) {
        setTradeVolumeEnable(true);
        setTradeRiskEnable(false);
      } else {
        setTradeVolumeEnable(true);
        setTradeRiskEnable(true);
      }
      setSyncAccount({
        ...syncAccount,
        master_strategy: {
          ...syncAccount.master_strategy,
          [name]: value,
        },
      });
    } else {
      setSyncAccount({ ...syncAccount, [name]: value });
    }
    if (name === "master_account_to_sync") {
      setMasterAccountId(value);
    }
    if (name === "use_existing_master_startegy") {
      setExsistingStrategyEnable(true);
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    const floatKeys = [
      "tradeVolume",
      "risk_fraction",
      "master_account_multiplier",
    ];

    const isValidFloat = (v) => /^\d*\.?\d+$/.test(v);
    const isValidInteger = (v) => /^\d+$/.test(v);
    if (floatKeys.includes(name)) {
      if (value === "" || isValidFloat(value)) {
        setSyncAccount({
          ...syncAccount,
          master_strategy: {
            ...syncAccount.master_strategy,
            [name]: value === "" ? "" : parseFloat(value),
          },
        });
      }
    } else if (name == "strategy_name") {
      setSyncAccount({
        ...syncAccount,
        master_strategy: { ...syncAccount.master_strategy, [name]: value },
      });
    } else {
      setSyncAccount({ ...syncAccount, [name]: value });
    }
  };

  useEffect(() => {
    getMasterAccounts();
    getSubscriberAccounts();
  }, []);

  return (
    <>
      <div className="createMasterAccoutTextStyle">Connect Accounts</div>
      <div className="createAccountRevampContainer">
        <div>
          <div className="AccoutTypeStyle">Master Account :</div>
          <div>
            <Select
              placeholder="Select master account"
              className="custom-select-broker"
              name="master_account_to_sync"
              onChange={(value) =>
                handleSelectChange(value, "master_account_to_sync")
              }
              style={{ width: "100%", marginBottom: "2%" }}
            >
              {masterStrategies?.map((server) => (
                <Select.Option key={server.id} value={server.id}>
                  {server.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="AccoutTypeStyle">Subscriber Account:</div>
          <div>
            <Select
              placeholder="Select Subscriber Account"
              className="custom-select-broker"
              name="user_account_to_sync"
              onChange={(value) =>
                handleSelectChange(value, "user_account_to_sync")
              }
              style={{
                width: "100%",
                marginBottom: "2%",
              }}
            >
              {subscriberStrategies?.map((server) => (
                <Select.Option key={server.id} value={server.id}>
                  {server.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="AccoutTypeStyle">Choose from Existing Strategy</div>
          <div>
            <Select
              placeholder="Select Existing Strategy"
              className="custom-select-broker"
              name="use_existing_master_startegy"
              onChange={(value) =>
                handleSelectChange(value, "use_existing_master_startegy")
              }
              style={{
                width: "100%",
                marginBottom: "2%",
              }}
            >
              {selectMasterStrategies?.map((server) => (
                <Select.Option key={server.id} value={server.id}>
                  {server.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="AccoutTypeStyle">Strategy Name:</div>
          <div>
            <Input
              placeholder="Type Here in the box"
              className="AccountSyncRevampInputStyle"
              name="strategy_name"
              value={syncAccount.master_strategy.strategy_name}
              onChange={handleOnChange}
              disabled={exsistingStrategyEnable}
            />
          </div>
        </div>
        <div>
          <div className="AccoutTypeStyle">Meta Sync Risk Mode</div>
          <div>
            <Select
              style={{
                width: "100%",
                marginBottom: "2%",
              }}
              placeholder="Select"
              className="custom-select-broker"
              name="risk_settings"
              value={syncAccount.master_strategy.risk_settings}
              onChange={(value) => {
                handleSelectChange(value, "risk_settings");
              }}
              disabled={exsistingStrategyEnable}
              options={[
                {
                  value: 5,
                  label: "Contract Size",
                },
                {
                  value: 4,
                  label: "Balance",
                },
                {
                  value: 2,
                  label: "Fixed Volume",
                },
                {
                  value: 3,
                  label: "Fixed Risk",
                },
                {
                  value: 1,
                  label: "None",
                },
              ]}
            />
          </div>
        </div>
        {!tradeVolumeEnable ? (
          <div>
            <div className="AccoutTypeStyle">Trade Volume:</div>
            <div>
              <Input
                placeholder="Type Here in the box"
                className="AccountSyncRevampInputStyle"
                name="tradeVolume"
                value={syncAccount.tradeVolume}
                onChange={handleOnChange}
                onInput={handleIntegerInput}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {!tradeRiskEnable ? (
          <div>
            <div className="AccoutTypeStyle">Trade Risk(%)</div>
            <div>
              <Input
                placeholder="Type Here in the box"
                className="AccountSyncRevampInputStyle"
                name="risk_fraction"
                value={syncAccount.risk_fraction}
                onChange={handleOnChange}
                onInput={handleIntegerInput}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div>
          <div className="AccoutTypeStyle">Multiplier:</div>
          <div>
            <Input
              placeholder="01"
              className="AccountSyncRevampInputStyle"
              name="master_account_multiplier"
              value={syncAccount.master_strategy.master_account_multiplier}
              onChange={handleOnChange}
              disabled={exsistingStrategyEnable}
            />
          </div>
        </div>
        <div>
          <div className="AccoutTypeStyle">Copy Pending Orders:</div>
          <div>
            <Select
              style={{
                width: "100%",
                marginBottom: "2%",
              }}
              placeholder="Select"
              className="custom-select-broker"
              name="copy_pending_orders"
              value={syncAccount.copy_pending_orders}
              disabled={exsistingStrategyEnable}
              onChange={(value) => {
                handleSelectChange(value, "copy_pending_orders");
              }}
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
            />
          </div>
        </div>
        <div>
          <MetaSyncButton
            text="Sync Account"
            className="creteAccountSyncButtonStyle"
            onClick={AccountSync}
            isLoading={isLoading}
            disabled={
              syncAccount.use_existing_master_startegy == ""
                ? syncAccount.master_account_to_sync == 0 ||
                  syncAccount.user_account_to_sync == 0 ||
                  syncAccount.master_strategy.master_account_multiplier == ""
                : syncAccount.master_account_to_sync == 0 ||
                  syncAccount.user_account_to_sync == 0 ||
                  syncAccount.use_existing_master_startegy == ""
            }
          />
        </div>
      </div>
    </>
  );
};
export default AccountSyncRevamp;
