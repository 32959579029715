import { MetaSyncButton } from "component/commonCompnent";
import { Collapse } from "antd";
import "./MetaFaqStyle.scss";

const MetaFaq = () => {
  const { Panel } = Collapse;
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <>
      <div className="homeourfeatureStyle">Frequently Asked Questions</div>
      <div className="homefeatureParaStyle">
        We've compiled a list of the most common questions we get asked. If you
        <br />
        have any other questions, please feel free to reach out to us.
      </div>
      <div className="contactContainer">
        <div className="faqFormStyle">
          <Collapse
            defaultActiveKey={["1"]}
            onChange={onChange}
            expandIconPosition="right"
            style={{ fontFamily: "Poppins" }}
          >
            <Panel
              header="What is copy trading?"
              key="1"
              style={{ padding: "20px" }}
            >
              <p>
                MetaSync software developed an electronic trading platform
                called MetaSync. It enables brokers and their retail clients to
                trade foreign exchange currencies, indices, and stocks.
              </p>
            </Panel>
            <Panel
              header="What is MetaSync?"
              key="2"
              style={{ padding: "25px" }}
            >
              <p>
                MetaSync software developed an electronic trading platform
                called MetaSync. It enables brokers and their retail clients to
                trade foreign exchange currencies, indices, and stocks.
              </p>
            </Panel>
            <Panel
              header="What is MT4/MT5?"
              key="3"
              style={{ padding: "25px" }}
            >
              <p>
                MetaSync software developed an electronic trading platform
                called MetaSync. It enables brokers and their retail clients to
                trade foreign exchange currencies, indices, and stocks.
              </p>
            </Panel>
            <Panel
              header="How does copy trading latency work?"
              key="4"
              style={{ padding: "20px" }}
            >
              <p>
                The rate at which a trade is copied from one account to another
                can be determined by several factors, the median internal
                latency of TradeMimic’s core copy trading service averages 10ms.
                Additionally, server location and execution speed of the broker
                can range anywhere from 200-2000ms.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="discordMainStyle" style={{ margin: "0 4% 5% 4%" }}>
        <div className="discordContainerStyle">
          <div className="AiHeadingStyle">Still Have Question?</div>
          <div className="AiParaStyle">
            Can’t find answer you’re looking for? Please chat to our friendly
            team.
          </div>
          <div>
            <div>
              <MetaSyncButton
                text={<>Get in Touch</>}
                className="communitySubscribeButtonStyle"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MetaFaq;
