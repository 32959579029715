import "./MetaPricingStyle.scss";
import HomePagePlan from "../HomePagePlan";
import { ArrowRightOutlined } from "@ant-design/icons";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import freeTrainingImage from "assets/images/freeTrainingImage.svg";
import seriousAboutImage from "assets/images/seriousAboutImage.svg";
import highestLevelImage from "assets/images/highestLevelImage.svg";

const MetaPricing = () => {
  return (
    <>
      <div>
        <HomePagePlan
          heading="Choose the Plan"
          description="Get Starter Now!"
        />
        <div>
          <div className="homeourfeatureStyle">Frequently asked questions</div>
          <div className="homefeatureParaStyle">
            We've compiled a list of the most common questions we get asked. If
            you have any other
            <br /> questions, please feel free to reach out to us.
          </div>
        </div>
        <div className="pricingFaqContainerStyle">
          <div className="pricingFaqFirstContainerStyle">
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">What is copy trading?</div>
              <div className="AiParaStyle">
                Copy trading allows individuals to automatically copy the
                positions taken by another trader’s account, in our case any
                MT4/MT5 compatible account.
              </div>
            </div>
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">What is MT4/MT5?</div>
              <div className="AiParaStyle">
                MT4 is the original software version (electronic trading
                platform) created by MetaQuotes in 2005. It’s successor, created
                in 2010 is called MT5. MT4 is designed for CFD forex
                instruments, whereas MT5 is an all-in-one state of the art
                multi-asset trading platform, covering both centralised and
                non-centralised financial markets.
              </div>
            </div>
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">
                How and when I pay for my Connections?
              </div>
              <div className="AiParaStyle">
                When you try to connect more than 3x MT4/MT5 accounts, you will
                be required to disclose your payment details in the billing
                section of your user profile. You will be charged at the end of
                each month from the point of connection.
              </div>
            </div>
          </div>
          <div className="pricingFaqFirstContainerStyle">
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">What is MetaSyc?</div>
              <div className="AiParaStyle">
                MetaSync software developed an electronic trading platform
                called MetaSync. It enables brokers and their retail clients to
                trade foreign exchange currencies, indices, and stocks.
              </div>
            </div>
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">
                How does copy trading latency work?
              </div>
              <div className="AiParaStyle">
                The rate at which a trade is copied from one account to another
                can be determined by several factors, the median internal
                latency of TradeMimic’s core copy trading service averages 10ms.
                Additionally, server location and execution speed of the broker
                can range anywhere from 200-2000ms.
              </div>
            </div>
            <div className="faqQueContainerStyle">
              <div className="pricingQuestionStyle">
                How many Accounts can I Add?
              </div>
              <div className="AiParaStyle">
                We have no upper bound on account connections, however, please
                reach out to us directly if you wish to connect 100+ accounts.
                We can help streamline the process by assisting with mass
                account migrations.
              </div>
            </div>
          </div>
        </div>
        <div className="pricingDiscordStyle">
          <div className="pricingdiscordMainStyle">
            <div className="discordContainerStyle">
              <div className="AiHeadingStyle">Join Our Discord Community</div>
              <div className="AiParaStyle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                risus duieu adipiscing elit.
              </div>
              <div>
                <div>
                  <MetaSyncButton
                    text={
                      <>
                        Subscribe Now
                        <ArrowRightOutlined />
                      </>
                    }
                    className="communitySubscribeButtonStyle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="freeTrainingContainerStyle">
            <div>
              <img src={freeTrainingImage} height={"30px"} />
            </div>
            <div>
              <img src={seriousAboutImage} height={"30px"} />
            </div>
            <div>
              <img src={highestLevelImage} height={"30px"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MetaPricing;
