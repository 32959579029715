import "./HomePageQuestionStyle.scss";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { getChartByID } from "apexcharts";
const { Panel } = Collapse;

const HomePageQuestion = () => {
  const text = `
  Our pricing plan is very simple, if you are an Individual member wanting to connect a single trading account, it's $15 per month, or $160 per year. If you need to connect more than 1 account, you can do so for $10 per account per month, or $100 per year. Therefore, 2 accounts would cost $20 per month or $200 per year.
`;
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "1px solid #D9D9D9",
    marginTop: 34,
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: 20,
  };
  return (
    <>
      <div className="HomePageQuestionMainStyle">
        <div className="homeourfeatureStyle">Frequently Asked Questions</div>
        <div className="homefeatureParaStyle">
          We're building the most trusted Trade Copier on the market.
        </div>
        <div>
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
            expandIconPosition="right"
            style={{ background: "#e9f6fa" }}
          >
            <Panel
              header="Do you use any Third Parties?"
              key="1"
              style={panelStyle}
            >
              <p className="faqTextStyle">{text}</p>
            </Panel>
            <Panel header="How does pricing work?" key="2" style={panelStyle}>
              <p className="faqTextStyle">{text}</p>
            </Panel>
            <Panel
              header="Do you offer a free trial?"
              key="3"
              style={panelStyle}
            >
              <p className="faqTextStyle">{text}</p>
            </Panel>
            <Panel
              header="Is Traders Connect reliable?"
              key="4"
              style={panelStyle}
            >
              <p className="faqTextStyle">{text}</p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default HomePageQuestion;
