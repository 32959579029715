import "./AsyncGeneralSettingStyle.scss";
import { Input, Table, Select } from "antd";
import { useState, useEffect } from "react";
import { getApiWithAuth, postApiWithAuth } from "component/utilis/apiWrapper";

const AsyncGeneralSetting = ({ accountSyncData }) => {
  const handleOnChange = (e) => {};
  const [tableData, setTableData] = useState([]);
  const [masterAccountName, setMasterAccountName] = useState("");

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  const columns = [
    {
      title: <span className="customColumnHeaderStyle">Accounts</span>,
      dataIndex: "account",
      key: "account",
    },
  ];

  useEffect(() => {
    setMasterAccountName(accountSyncData?.master_account?.account_name);
    if (Object.entries(accountSyncData).length > 0) {
      const data = accountSyncData?.synced_accounts.map((item) => {
        return {
          account: (
            <>
              <span className="customRowStyle">
                {item?.user_account.account_name}
              </span>
            </>
          ),
        };
      });
      setTableData(data);
    }
  }, [accountSyncData]);

  return (
    <>
      <div className="GeneralSettingMainContainer">
        <div className="generalSettingHeadingContainer">
          <div className="createMasterAccoutTextStyle">General </div>
        </div>
        <div className="generalSettingInputFieldContainer">
          <div className="masterinputFieldContainerRevampRight">
            <div className="AccoutTypeStyle">Master Account :</div>
            <div>
              <Input
                className="AccountRiskInputStyle"
                name="masterAccountName"
                type="number"
                placeholder={masterAccountName}
                disabled
              />
            </div>
          </div>
          <div className="masterinputFieldContainerRevampRight">
            <div className="AccoutTypeStyle">Subscriber Account :</div>

            <div>
              <div className="mapTableContainer">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{
                    y: 240,
                  }}
                  style={{ width: "500px" }}
                  pagination={{
                    pageSize: 5,
                    position: ["bottomRight"],
                    size: "default",
                    itemRender: itemRender,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsyncGeneralSetting;
