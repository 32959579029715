import "./HomePageStyle.scss";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { PlayCircleOutlined } from "@ant-design/icons";
import HomePageMainImage from "assets/images/homePageImg.svg";
import HomePageGraphImage from "assets/images/homePageGraph.svg";
import LayersImage from "assets/images/layersImage.svg";
import HomePageFeature from "../HomePageFeature";
import HomePageTelegramCopier from "../HomePageTelegramCopier";
import HomePagePlatform from "../HomePagePlatform";
import HomePageMetaAdvance from "../HomePageMetaAdvance";
import HomePagePlan from "../HomePagePlan";
import HomePageQuestion from "../HomePageQuestion";
import HomePageCarosoule from "../HomePageCarosoule";
import { ArrowRightOutlined } from "@ant-design/icons";
import Layer from "assets/images/layers.svg";
import Quotient from "assets/images/Quotient.svg";
import Circools from "assets/images/Circools.svg";
import Hourglass from "assets/images/Hourglass.svg";
import Command from "assets/images/Command.svg";
import { useEffect } from "react";
import { getToken } from "component/utilis/localStorage";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (getToken()) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <div className="homePageMainStyle">
        <div className="homePageFirstContainerStyle">
          <div className="AiContainerStyle">
            <div className="AiHeadingStyle">
              The #1 Ai Trade Copier for MetaTrader & Telegram
            </div>
            <div className="AiParaStyle">
              The #1 Ai trade copier tool for MetaTrader & Telergam Channels -
              <br />
              No VPS <br />
              required The #1 Ai trade cop Channels - No VPS required
            </div>
            <div className="trailButtonContainer">
              <div>
                <MetaSyncButton
                  text="5 Days Free Trial"
                  className="freeTrialButtonStyle"
                />
              </div>
              <div>
                <MetaSyncButton
                  text={
                    <>
                      Play Demo &nbsp;
                      <PlayCircleOutlined />
                    </>
                  }
                  className="demoButtonStyle"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="HomePageMainImageContainerStyle">
              <img src={HomePageMainImage} className="HomePageMainImageStyle" />
            </div>
            <div className="HomePageGraphContainerImageStyle">
              <img src={HomePageGraphImage} />
            </div>
          </div>
        </div>
        <div className="homepageSecondContainer">
          <div className="workContainerStyle">We work with every broker</div>
          <div className="layersImageContainerStyle">
            <div className="layersDivStyle">
              <div>
                <img src={Layer} alt="layers" />
              </div>
              <div>
                <img src={Quotient} alt="quotient" />
              </div>
              <div>
                <img src={Circools} alt="circools" />
              </div>
              <div>
                <img src={Hourglass} alt="hourglass" />
              </div>
              <div>
                <img src={Command} alt="command" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomePageFeature />
        </div>
        <div>
          <HomePageTelegramCopier />
        </div>
        <div>
          <HomePagePlatform />
        </div>
        <div>
          <HomePageMetaAdvance />
        </div>
        <div>
          <HomePageCarosoule />
        </div>
        <div className="discordMainStyle">
          <div className="discordContainerStyle">
            <div className="AiHeadingStyle">Join Our Discord Community</div>
            <div className="AiParaStyle">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              risus duieu adipiscing elit.
            </div>
            <div>
              <div>
                <MetaSyncButton
                  text={
                    <>
                      Subscribe Now
                      <span>
                        <ArrowRightOutlined style={{ paddingLeft: "5%" }} />
                      </span>
                    </>
                  }
                  className="communitySubscribeButtonStyle"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomePagePlan
            heading="Plans built for everyone"
            description="Get the power and customization you need to build world-class projects"
          />
        </div>
        <div>
          <HomePageQuestion />
        </div>
      </div>
    </>
  );
};
export default HomePage;
