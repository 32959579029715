import "./BillingComponentStyle.scss";
import { Table, Spin, Radio, message, Switch, Modal } from "antd";
import { MetaSyncButton } from "component/commonCompnent";
import informationicon from "assets/images/information-button.svg";
import BillingDetails from "../BillingDetails";
import { autoDownloadFile } from "component/utilis/constant";
import { getApiWithAuth, postApiWithAuth } from "component/utilis/apiWrapper";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
const BillingComponent = () => {
  const [isCreated, setIsCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [billingObject, setBillingObject] = useState([]);
  const [isAutoRenewal, setIsAutoRenewal] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [isInvoiceData, setInvoiceData] = useState([]);
  const [value, setValue] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getTableContent = async () => {
    const response = await getApiWithAuth(
      `payments/get-meta-user-account-subscription?is_platform_subscription=${value}`
    );
    setBillingObject(response?.data);
  };

  const getInvoice = async (id) => {
    const response = await getApiWithAuth(
      `payments/get-invoices?meta_user_account_id=${id}`
    );
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setInvoice(response.data);
    showModal();
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onChangeToggle = (checked) => {
    setIsAutoRenewal(!checked);
  };

  useEffect(() => {
    getTableContent();
  }, [value]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const invoiceColumn = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount Due",
      dataIndex: "amount_due",
      key: "amount_due",
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
    },
    {
      title: "Created At",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "Download",
      dataIndex: "pdf",
      key: "pdf",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    if (invoice) {
      const data = invoice.map((item) => ({
        amount_due: item?.amount_due,
        id: item?.id,
        amount_paid: item?.amount_paid,
        pdf: (
          <MetaSyncButton
            text="Download Invoice"
            className="metabillingButtonStyle"
            onClick={() => autoDownloadFile(item.pdf)}
          />
        ),
        created: item?.created,
        status: item?.status,
      }));
      setInvoiceData(data);
    }
  }, [invoice]);

  const columns = [
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
  ];
  const accountColumn = [
    {
      title: "Account Number",
      dataIndex: "account_number",
      key: "account_number",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Re-Subscribe",
      key: "resubscribe",
      dataIndex: "resubscribe",
    },
    {
      title: "Invoice",
      key: "invoice",
      dataIndex: "invoice",
    },
    {
      title: "Auto Renewal",
      key: "auto",
      dataIndex: "auto",
    },
  ];

  const ReSubscribe = async (id) => {
    setIsLoading(true);
    const response = await postApiWithAuth(`payments/retry-payment`, {
      meta_user_account_id: id,
    });
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success(`${response.data.message}`);
  };

  const AutoRenewal = async (id) => {
    const response = await postApiWithAuth(
      `payments/update-subscription-auto-renewal`,
      {
        meta_user_account_id: id,
        cancel_at_period_end: isAutoRenewal,
      }
    );
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success(`${response.data.message}`);
  };

  useEffect(() => {
    if (billingObject) {
      const data = billingObject.map((item) => ({
        account_name: item?.account_name,
        account_number: item?.account_number,
        role: item?.copy_factory_role,
        resubscribe: (
          <MetaSyncButton
            text="Re subscribe"
            className="metabillingButtonStyle"
            onClick={() => ReSubscribe(item.id)}
            disabled={item.stripe_subscription_status === "Active"}
          />
        ),
        invoice: (
          <MetaSyncButton
            text="Invoice"
            className="metRenewalButtonStyle"
            onClick={() => getInvoice(item.id)}
          />
        ),
        auto: (
          <Switch
            defaultChecked
            onChange={onChangeToggle}
            onClick={() => AutoRenewal(item.id)}
          />
        ),
      }));
      setTableData(data);
    }
  }, [billingObject]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  return (
    <>
      <div className="marketplaceListStyle">
        <div className="masterHeadingContainerStyle">
          <div className="backMasterContaierStyle">
            {isCreated ? (
              <div>
                <MetaSyncButton
                  text={
                    <>
                      <ArrowLeftOutlined />
                    </>
                  }
                  onClick={() => setIsCreated(false)}
                  className="backButtonStyle"
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {!isCreated ? (
                <div className="masterAccountHeading">
                  Billing
                  <sup>
                    <img src={informationicon} />
                  </sup>
                </div>
              ) : (
                <div className="masterAccountHeading">
                  Billing Details
                  <sup>
                    <img src={informationicon} />
                  </sup>
                </div>
              )}
              <div className="masterLinkStyle">
                <u className="clickhereStyle">Click here </u>&nbsp; to learn
                About Billing
              </div>
            </div>
          </div>
          <div>
            {!isCreated ? (
              <div>
                <MetaSyncButton
                  text="Update Payment Details"
                  onClick={() => setIsCreated(true)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!isCreated ? (
          isLoading ? (
            <div className="tableListBoxStyle">
              <Spin
                indicator={antIcon}
                size="large"
                centered
                className="spinClass"
              />
            </div>
          ) : (
            <>
              <div className="tableListBoxStyle">
                <Table
                  columns={value ? columns : accountColumn}
                  dataSource={tableData}
                  className="listTableStyle"
                  scroll={{
                    y: 240,
                  }}
                  pagination={{
                    pageSize: 5,
                    position: ["bottomRight"],
                    size: "default",
                    itemRender: itemRender,
                  }}
                  title={() => (
                    <>
                      <div className="billingHeaderStyle">
                        <div className="tableHeaderStyle">
                          <div>
                            Billing Details
                            <sup>
                              <img src={informationicon} />
                            </sup>
                          </div>
                        </div>
                        {!isCreated ? (
                          <div className="accountplatformStyle">
                            <div className="typeStyle">Subscription Type:</div>

                            <Radio.Group
                              onChange={onChange}
                              value={value}
                              style={{ display: "flex" }}
                            >
                              <Radio value={false}>Account</Radio>
                              <Radio value={true}>Platform</Radio>
                            </Radio.Group>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                />
              </div>
            </>
          )
        ) : (
          <div className="tableBoxStyle">
            <BillingDetails setIsCreated={setIsCreated} />
          </div>
        )}
        {!isCreated ? (
          <div className="paymentMethodStyle">
            <div>
              Next payment date:
              <span className="nextPaymentDateStyle">
                {billingObject[0]?.stripe_subscription_end_date}
              </span>
            </div>
            <div>
              Payment method:
              <span className="nextPaymentDateStyle">
                {billingObject[0]?.stripe_subscription_card_type}
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <Table
          columns={invoiceColumn}
          dataSource={isInvoiceData}
          className="listTableStyle"
          scroll={{
            y: 240,
          }}
          pagination={{
            pageSize: 5,
            position: ["bottomRight"],
            size: "default",
            itemRender: itemRender,
          }}
          title={() => (
            <>
              <div className="tableHeaderStyle">Invoice Details</div>
            </>
          )}
        />
      </Modal>
    </>
  );
};
export default BillingComponent;
