import React from "react";
import { handleIntegerInput } from "component/utilis/constant";
import { Input, Select } from "antd";
import Billing from "../Billing";
import "./BillingDetailsStyle.scss";
import countries from "i18n-iso-countries";
import { useState, useEffect } from "react";
const BillingDetails = () => {
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  const [billingDetailData, setBillingDetailData] = useState({
    name: "",
    company_name: "",
    vat_number: "",
    address: "",
    street: "",
    country: "",
    zip_code: "",
  });

  const [countryNames, setCountryNames] = useState([]);

  useEffect(() => {
    const names = countries.getNames("en");
    setCountryNames(names);
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBillingDetailData({ ...billingDetailData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    setBillingDetailData({ ...billingDetailData, [name]: value });
  };
  return (
    <>
      <div className="createMasterAccoutTextStyle">Billing Information</div>
      <div className="masterinputFieldContainer">
        <div className="masterinputFieldContainerLeft">
          <div className="AccoutTypeStyle">Name:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              onChange={handleOnChange}
              name="name"
              value={billingDetailData.name}
            />
          </div>
        </div>
        <div className="masterinputFieldContainerRight">
          <div className="AccoutTypeStyle">Company Name:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              name="company_name"
              value={billingDetailData.company_name}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      <div className="masterinputFieldContainer">
        <div className="masterinputFieldContainerLeft">
          <div className="AccoutTypeStyle">VAT Number:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              name="vat_number"
              value={billingDetailData.vat_number}
              onChange={handleOnChange}
              onInput={handleIntegerInput}
            />
          </div>
        </div>
        <div className="masterinputFieldContainerRight">
          <div className="AccoutTypeStyle">Address:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              name="address"
              value={billingDetailData.address}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      <div className="masterinputFieldContainer">
        <div className="masterinputFieldContainerLeft">
          <div className="AccoutTypeStyle">Street:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              name="street"
              value={billingDetailData.street}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="masterinputFieldContainerRight">
          <div className="AccoutTypeStyle">Country:</div>
          <div>
            <div>
              <Select
                placeholder="Select master account"
                className="custom-select-broker"
                name="master_account_to_sync"
                onChange={(value) => handleSelectChange(value, "country")}
                style={{ width: "80%" }}
              >
                {Object.values(countryNames).map((country, index) => (
                  <Select.Option key={index} value={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="masterinputFieldContainer">
        <div className="masterinputFieldContainerLeft">
          <div className="AccoutTypeStyle">Zip/Postal Code:</div>
          <div>
            <Input
              placeholder="Type here in the box"
              className="cardHolderNameStyle"
              name="zip_code"
              value={billingDetailData.zip_code}
              onChange={handleOnChange}
              onInput={handleIntegerInput}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "25px" }}>
        <hr />
      </div>
      <div>
        <Billing billingDetailData={billingDetailData} isUpdate={true} />
      </div>
    </>
  );
};
export default BillingDetails;
