import "./UserAccountStyle.scss";
import { useEffect, useState } from "react";
import { Space, Table, Tag, Spin, Input, message } from "antd";
import { MetaSyncButton } from "component/commonCompnent";
import informationicon from "assets/images/information-button.svg";
import deleteIcon from "assets/images/tableDeleteIcon.svg";
import listIcon from "assets/images/tableListIcon.svg";
import listTable from "assets/images/ListTable.svg";
import { getApiWithAuth, deleteApiWithAuth } from "component/utilis/apiWrapper";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import CreateSubscriberAccountRevamp from "../CreateSubscriberAccountRevamp";
import SubscriberTrade from "../SubscriberTrade";
import checkedGreen from "assets/images/checkedGreen.svg";

const UserAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalListOpen, setIsModalListOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [userAccountData, setUserAccoutData] = useState([]);
  const [selectedItem, setselectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isListId, setIsListId] = useState(0);
  const [isListAccountName, setIsListAccountName] = useState(0);
  const navigate = useNavigate();
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const searchOnchange = (event) => {
    setSearchValue(event.target.value);
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setselectedItem(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteUserAccount();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showListModal = (id, name) => {
    setIsModalListOpen(true);
    setIsListId(id);
    setIsListAccountName(name);
  };
  const handleListOk = () => {
    setIsModalListOpen(false);
  };
  const handleListCancel = () => {
    setIsModalListOpen(false);
  };
  const geUserAccount = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth("accounts/get-user-accounts");
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setUserAccoutData(response?.data);
  };

  const geFilterUserAccount = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth(
      `accounts/get-user-accounts?search=${searchValue}`
    );
    setIsLoading(false);
    if (response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setUserAccoutData(response?.data);
  };

  useEffect(() => {
    geUserAccount();
  }, [isCreated]);
  const deleteUserAccount = async () => {
    const response = await deleteApiWithAuth(
      `accounts/${selectedItem}/delete-user-account`
    );
    if (response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Deleted Succesfully");
    geUserAccount();
  };
  const columns = [
    {
      title: <span className="customColumnHeaderStyle">Account Name</span>,
      dataIndex: "accountname",
      key: "accountname",
    },
    {
      title: <span className="customColumnHeaderStyle">Account Number</span>,
      dataIndex: "accountnumber",
      key: "accountnumber",
    },
    {
      title: <span className="customColumnHeaderStyle">Broker Server</span>,
      dataIndex: "brokerserver",
      key: "brokerserver",
    },
    {
      title: <span className="customColumnHeaderStyle">Balance</span>,
      key: "balance",
      dataIndex: "balance",
    },
    {
      title: <span className="customColumnHeaderStyle">Deposit</span>,
      key: "deposit",
      dataIndex: "deposit",
    },
    {
      title: <span className="customColumnHeaderStyle">Withdrawals</span>,
      key: "withdrawals",
      dataIndex: "withdrawals",
    },
    {
      title: <span className="customColumnHeaderStyle">Account Type</span>,
      key: "accountType",
      dataIndex: "accountType",
    },
    {
      title: <span className="customColumnHeaderStyle">Status</span>,
      key: "status",
      dataIndex: "status",
    },
    {
      title: <span className="customColumnHeaderStyle">Options</span>,
      key: "options",
      dataIndex: "options",
    },
  ];
  useEffect(() => {
    if (userAccountData) {
      const data = userAccountData.map((item) => ({
        accountname: (
          <span className="customRowStyle">{item.account.account_name}</span>
        ),
        accountnumber: (
          <span className="customRowStyle">{item.account.account_number}</span>
        ),
        brokerserver: (
          <span className="customRowStyle">{item.account.broker_name}</span>
        ),
        balance: <span className="customRowStyle">{item.stats.Balance}</span>,
        deposit: <span className="customRowStyle">{item.stats.Deposits}</span>,
        status: (
          <span className="customRowStyle">
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {item.stats.Status === "Connected" ? (
                    <img
                      src={checkedGreen}
                      width={15}
                      height={15}
                      style={{
                        paddingTop: "9px",
                        paddingRight: "5px",
                      }}
                    />
                  ) : (
                    <div style={{ paddingRight: "5px" }}>
                      <CloseCircleFilled style={{ color: "red" }} />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    color:
                      item.stats.Status === "Connected" ? "#61DE7E" : "red",
                  }}
                >
                  {item.stats.Status}
                </div>
              </div>
            </>
          </span>
        ),
        withdrawals: (
          <span className="customRowStyle">{item.stats.Withdrawals}</span>
        ),
        accountType: (
          <span className="customRowStyle">{item.account.account_type}</span>
        ),

        connectedaccounts: (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                {item.connection_status === "Connected" ? (
                  <img
                    src={checkedGreen}
                    width={15}
                    height={15}
                    style={{
                      paddingTop: "9px",
                      paddingRight: "5px",
                    }}
                  />
                ) : (
                  <div style={{ paddingRight: "5px" }}>
                    <CloseCircleFilled style={{ color: "red" }} />
                  </div>
                )}
              </div>
              <div
                style={{
                  color:
                    item.connection_status === "Connected" ? "#61DE7E" : "red",
                }}
              >
                {item.connection_status}
              </div>
            </div>
          </>
        ),
        options: (
          <>
            <div>
              <img
                src={listTable}
                width={15}
                height={15}
                onClick={() =>
                  showListModal(item.account.id, item.account.account_name)
                }
                style={{ cursor: "pointer", marginRight: "2%" }}
              />
              <img
                src={listIcon}
                width={15}
                height={15}
                onClick={() =>
                  navigate("/editUserAccount", {
                    state: {
                      id: item.account.id,
                      name: item.account.account_name,
                    },
                  })
                }
                style={{
                  cursor: "pointer",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                }}
              />
              <img
                src={deleteIcon}
                width={15}
                height={15}
                onClick={() => showModal(item.account.id)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        ),
      }));
      setTableData(data);
    }
  }, [userAccountData]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  return (
    <>
      <div className="marketplaceListStyle">
        <div className="masterHeadingContainerStyle">
          <div className="backMasterContaierStyle">
            {isCreated ? (
              <div>
                <MetaSyncButton
                  text={
                    <>
                      <ArrowLeftOutlined />
                    </>
                  }
                  onClick={() => setIsCreated(false)}
                  className="backButtonStyle"
                />
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="masterAccountHeading">
                Subscriber Account
                <sup>
                  <img src={informationicon} className="titleInformationIcon" />
                </sup>
              </div>
              <div className="masterLinkStyle">
                <u className="clickhereStyle">Click here </u>&nbsp; to learn
                About the Subscriber Account Page
              </div>
            </div>
          </div>
          <div>
            {!isCreated ? (
              <div>
                <MetaSyncButton
                  text="Add Subscriber Account"
                  onClick={() => setIsCreated(true)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!isCreated ? (
          isLoading ? (
            <div className="tableListBoxStyle">
              <Spin
                indicator={antIcon}
                size="large"
                centered
                className="spinClass"
              />
            </div>
          ) : (
            <div className="tableListBoxStyle">
              <Table
                columns={columns}
                dataSource={tableData}
                scroll={{
                  y: 240,
                }}
                pagination={{
                  pageSize: 5,
                  position: ["bottomRight"],
                  size: "default",
                  itemRender: itemRender,
                }}
                title={() => (
                  <>
                    <div className="billingHeaderStyle">
                      <div className="tableHeaderStyle">
                        Account Connections
                        <sup>
                          <img
                            src={informationicon}
                            className="titleInformationIcon"
                          />
                        </sup>
                      </div>
                      <div>
                        <Search
                          placeholder="Search"
                          onSearch={geFilterUserAccount}
                          enterButton
                          name="searchValue"
                          value={searchValue}
                          onChange={searchOnchange}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            </div>
          )
        ) : (
          <div className="tableBoxStyle">
            <CreateSubscriberAccountRevamp setIsCreated={setIsCreated} />
          </div>
        )}
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          okText={"Yes"}
          cancelText={"No"}
          className="centered-modal-footer"
        >
          <div className="listDeleteStyle">
            Are you sure you want to delete this account?
          </div>
        </Modal>
        <Modal
          open={isModalListOpen}
          onOk={handleListOk}
          onCancel={handleListCancel}
          centered={true}
          okText={"Yes"}
          cancelText={"No"}
          width={"100%"}
          footer={null}
        >
          <SubscriberTrade
            isListId={isListId}
            isListAccountName={isListAccountName}
          />
        </Modal>
      </div>
    </>
  );
};
export default UserAccount;
