import "./SidebarStyle.scss";
import Logo from "assets/images/Logo.svg";
import LogoTwo from "assets/images/SideLogo.svg";
import ProfilePicture from "assets/images/profilePicture.webp";
import DashboardIcon from "assets/images/dashboardIcon.svg";
import AccountSyncIcon from "assets/images/AccountSyncNew1.svg";
import BillingIcon from "assets/images/billingIcon.svg";
import LogoutIcon from "assets/images/logout.svg";
import MasterAccountIcon from "assets/images/masterAccountIcon.svg";
import SubscribeIcon from "assets/images/subscriberIcon.svg";
import TermandConditionIcon from "assets/images/termIcon.svg";
import ContactUsIcon from "assets/images/contactsIcon.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import { deleteToken, removeIsPaid } from "component/utilis/localStorage";
import { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import { getIsPaid } from "component/utilis/localStorage";
import { Subscription } from "..";
import { postApiWithAuth } from "component/utilis/apiWrapper";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ children, isOpen }) => {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();
  const logoutUser = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    deleteToken();
    removeIsPaid();
  };

  const [isModalPaidOpen, setIsModalPaidOpen] = useState(false);

  const showModalPaid = () => {
    setIsModalPaidOpen(true);
  };
  const handleOkPaid = () => {
    if (getIsPaid() === true) {
      setIsModalPaidOpen(false);
    }
  };

  const handleCancelPaid = () => {
    if (getIsPaid() === true) {
      setIsModalPaidOpen(false);
    }
  };

  useEffect(() => {
    if (getIsPaid() === false) {
      showModalPaid();
    }
  }, []);
  const MenuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      img: DashboardIcon,
    },
    {
      path: `/marketplaceList`,
      name: "Master Account",
      img: MasterAccountIcon,
    },
    {
      path: `/createUserAccount`,
      name: "Subscriber Account",
      img: SubscribeIcon,
    },
    {
      path: `/accountSync`,
      name: "Account sync",
      img: AccountSyncIcon,
    },
    {
      path: `/billingDetails`,
      name: "Billing",
      img: BillingIcon,
    },
    {
      path: `/contactUs`,
      name: "Contact us",
      img: ContactUsIcon,
    },
    {
      path: `/termAndCondition`,
      name: "Terms & Conditions",
      img: TermandConditionIcon,
    },
  ];

  return (
    <>
      <div className="sideBarStyle">
        <div className={isOpen ? "sideBarMainStyle" : "sideBarMainOpenStyle"}>
          <div className="sideBarCpntentStyle">
            <div
              className="logoContainerStyle"
              onClick={() => navigate("/dashboard")}
            >
              <img src={isOpen ? Logo : LogoTwo} alt="Logo" />
            </div>
            <div>
              <hr className="hrStyle" />
            </div>

            <div className="sidebarContentLogoutContainerStyle">
              <div
                className={
                  isOpen
                    ? "sidebarContentMainContainerStyle"
                    : "sidebarContentMainOpenContainerStyle"
                }
              >
                {MenuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="SidebarContentStyle"
                    activeClassName="active"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={
                        isOpen ? "sidebarIconStyle" : "sidebarIconOpenStyle"
                      }
                    >
                      {item.name === "Account sync" ? (
                        <img src={item.img} style={{ width: "24px" }} />
                      ) : (
                        <img src={item.img} />
                      )}
                    </div>
                    <div
                      className="sideBarTextStyle"
                      style={{ display: isOpen ? "block" : "none" }}
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>

              <div
                className={
                  isOpen ? "SidebarLoguttStyle" : "SidebarLoguttOpenStyle"
                }
                onClick={logoutUser}
              >
                <div>
                  <img
                    src={LogoutIcon}
                    alt="dashboardIcon"
                    className={
                      isOpen
                        ? "sidebarLogoutIconStyle"
                        : "sidebarLogoutIconOpenStyle"
                    }
                  />
                </div>
                <div
                  className="sideBarTextStyle"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: isOpen ? "80%" : "95%" }}>
          <div
            className={
              isOpen
                ? "sidebarMainComponentStyle"
                : "sidebarMainOpenComponentStyle"
            }
          >
            {children}
          </div>
        </div>
      </div>

      <Modal
        title="Set your plan"
        visible={isModalPaidOpen}
        onOk={handleOkPaid}
        onCancel={handleCancelPaid}
        width={1000}
        footer={null}
        closable={false}
      >
        <Subscription setIsModalPaidOpen={setIsModalPaidOpen} />
      </Modal>
    </>
  );
};
export default Sidebar;
