import { Select, DatePicker, Table, message, Spin } from "antd";
import informationicon from "assets/images/information-button.svg";
import { getApiWithAuth } from "component/utilis/apiWrapper";
import { autoDownloadFile } from "component/utilis/constant";
import { useEffect, useState } from "react";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
const SubscriberTrade = ({ isListId, isListAccountName }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openTradeLoading, setOpenTradeLoading] = useState(false);
  const [openTradeData, setOpenTradeData] = useState([]);
  const onChangeDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };
  const downloadLogs = async () => {
    const response = await getApiWithAuth(
      `accounts/${isListId}/get-log-data?start_time=${startDate}&end_time=${endDate}`
    );
    if (!response.data) {
      return;
    }
    autoDownloadFile(response.data.url);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const getOpenTrade = async () => {
    setOpenTradeLoading(true);
    const response = await getApiWithAuth(
      `accounts/${isListId}/get-trade-history`
    );
    setOpenTradeLoading(false);
    if (!response.success) {
      return;
    }
    const data = response.data?.historical_data.map((item) => ({
      id: isListId,
      account: isListAccountName,
      ticket: item.id,
      opentime: item.time,
      symbol: item.symbol,
      type: (
        <>
          {item.type == "POSITION_TYPE_SELL" ? (
            <div style={{ color: "green" }}>SELL</div>
          ) : (
            <div style={{ color: "red" }}>BUY</div>
          )}
        </>
      ),
      lots: item.volume,
      openprice: item.openPrice,
      stoploss: (
        <>
          {item.stopLoss !== undefined ? (
            <div style={{ color: "red" }}>{item.stopLoss} </div>
          ) : (
            <div style={{ color: "red" }}>0</div>
          )}
        </>
      ),
      takeprofit: (
        <>
          {item.takeProfit !== undefined ? (
            <div style={{ color: "green" }}>{item.takeProfit}</div>
          ) : (
            <div style={{ color: "green" }}>0</div>
          )}
        </>
      ),
      com: item.commission,
      swap: item.swap,
      profit: item.profit,
    }));
    setOpenTradeData(data);
  };
  useEffect(() => {
    getOpenTrade();
  }, [isListId]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  const columns = [
    {
      title: <span className="customColumnHeaderStyle">ID</span>,
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: <span className="customColumnHeaderStyle">Account</span>,
      dataIndex: "account",
      key: "account",
      width: 80,
    },
    {
      title: <span className="customColumnHeaderStyle">Ticket</span>,
      dataIndex: "ticket",
      key: "ticket",
      width: 80,
    },
    {
      title: <span className="customColumnHeaderStyle">Open Time</span>,
      key: "opentime",
      dataIndex: "opentime",
      width: 100,
    },
    {
      title: <span className="customColumnHeaderStyle">Symbol</span>,
      key: "symbol",
      dataIndex: "symbol",
      width: 80,
    },
    {
      title: <span className="customColumnHeaderStyle">Type</span>,
      key: "type",
      dataIndex: "type",
      width: 80,
    },
    {
      title: <span className="customColumnHeaderStyle">Lots</span>,
      key: "lots",
      dataIndex: "lots",
      width: 70,
    },
    {
      title: <span className="customColumnHeaderStyle">Open Price</span>,
      key: "openprice",
      dataIndex: "openprice",
      width: 100,
    },
    {
      title: <span className="customColumnHeaderStyle">Stop Loss</span>,
      key: "stoploss",
      dataIndex: "stoploss",
      width: 100,
    },
    {
      title: <span className="customColumnHeaderStyle">Take Profit</span>,
      key: "takeprofit",
      dataIndex: "takeprofit",
      width: 100,
    },
    {
      title: <span className="customColumnHeaderStyle">Com</span>,
      key: "com",
      dataIndex: "com",
      width: 70,
    },
    {
      title: <span className="customColumnHeaderStyle">Swap</span>,
      key: "swap",
      dataIndex: "swap",
      width: 70,
    },
    {
      title: <span className="customColumnHeaderStyle">Profit</span>,
      key: "profit",
      dataIndex: "profit",
      width: 70,
    },
  ];
  return (
    <>
      <div>
        <div className="tradeContainerStyle">
          <div className="accountdetailStyle">
            Trades
            <sup>
              <img src={informationicon} className="titleInformationIcon" />
            </sup>
          </div>
          <div className="masterDateDownloadcontainer">
            <div className="mastercontainer">
              <div className="dateHeadingStyle">
                <span className="tofromStyle">FROM</span>
                <DatePicker
                  onChange={onChangeDate}
                  className="dateHeadingStyle"
                  disabledDate={disabledDate}
                />
              </div>
              <div className="dateHeadingStyle">
                <span className="tofromStyle">TO</span>
                <DatePicker
                  onChange={onChangeEndDate}
                  className="dateHeadingStyle"
                  disabledDate={disabledDate}
                />
              </div>
            </div>
            <div>
              <MetaSyncButton
                text="Download log"
                onClick={downloadLogs}
                disabled={startDate == "" || endDate == ""}
                className="downloadLogButtonStyle"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {openTradeLoading ? (
          <Spin
            indicator={antIcon}
            size="large"
            centered
            className="spinClass"
          />
        ) : (
          <Table
            className="tradeTable"
            columns={columns}
            dataSource={openTradeData}
            scroll={{
              y: 240,
            }}
            pagination={{
              pageSize: 5,
              position: ["bottomRight"],
              size: "default",
              itemRender: itemRender,
            }}
            title={() => (
              <>
                <div className="tableHeaderStyle">Open Trades</div>
              </>
            )}
          />
        )}
      </div>
    </>
  );
};
export default SubscriberTrade;
