import "./AccountSyncSettingStyle.scss";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import informationicon from "assets/images/information-button.svg";
import AsyncGeneralSetting from "../AsyncGeneralSetting";
import AsyncRiskSetting from "../AsyncRiskSetting";
import AsyncStopAndLimit from "../AsyncStopAndLimit";
import AsyncSymbol from "../AsyncSymbol";
import AsyncMapSymbol from "../AsyncMapSymbol";
import { getApiWithAuth, patchApiWithAuth } from "component/utilis/apiWrapper";
import { useLocation } from "react-router-dom";
import { Tabs, message } from "antd";
import { useEffect, useState } from "react";

const AccountSyncSetting = () => {
  const location = useLocation();
  const { id } = location.state;
  const [accountSyncData, setAccountSyncData] = useState({});
  const [updatedAccountSyncData, setUpdatedAccountSyncData] = useState({});
  const [updateSymbol, setUpdateSmbol] = useState("");
  const [symbols, setSymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isExcludeLoading, setIsExcludeLoading] = useState(false);
  const [showSymbol, setShowSymbol] = useState(false);

  const [symbolMapping, setSymbolMapping] = useState({
    to_symbol: "",
    from_symbol: "",
  });
  const [tradeVolumeEnable, setTradeVolumeEnable] = useState(true);
  const [tradeRiskEnable, setTradeRiskEnable] = useState(true);
  const UpdatedAccountSync = async () => {
    setIsLoading(true);
    let response;
    if (symbols) {
      response = await patchApiWithAuth(`accounts/${id}/strategies`, {
        ...updatedAccountSyncData,
        excluded_symbols:
          accountSyncData.excluded_symbols != null &&
          accountSyncData.excluded_symbols != ""
            ? accountSyncData.excluded_symbols + "," + symbols
            : symbols,
      });
      if (response.success) {
        setUpdateSmbol("");
      }
    } else {
      response = await patchApiWithAuth(
        `accounts/${id}/strategies`,
        updatedAccountSyncData
      );
    }
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Data updated successfully");
    getAccountSyncData();
  };
  const deleteMapSymbol = async (from, to) => {
    setIsDeleteLoading(true);
    const response = await patchApiWithAuth(`accounts/${id}/symbol-delete`, {
      symbol_mappings: [{ to_symbol: to, from_symbol: from }],
    });
    setIsDeleteLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Data deleted successfully");
    getAccountSyncData();
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const getAccountSyncData = async () => {
    const response = await getApiWithAuth(`accounts/${id}/get-master-strategy`);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setAccountSyncData(response.data);
  };

  const handleSyncSelectChange = (value, name) => {
    if (name == "risk_setting") {
      if (value == "fixedRisk") {
        setTradeVolumeEnable(false);
        setTradeRiskEnable(true);
      } else if (value == "fixedVolume") {
        setTradeVolumeEnable(true);
        setTradeRiskEnable(false);
      } else {
        setTradeVolumeEnable(true);
        setTradeRiskEnable(true);
      }
    }
    setAccountSyncData({ ...accountSyncData, [name]: value });
    setUpdatedAccountSyncData({ ...updatedAccountSyncData, [name]: value });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (
      name == "slippage" ||
      name == "min_trade_volume" ||
      name == "max_trade_volume" ||
      name == "multiplier"
    ) {
      setAccountSyncData({
        ...accountSyncData,
        [name]: value,
      });
      setUpdatedAccountSyncData({
        ...updatedAccountSyncData,
        [name]: parseInt(value),
      });
    } else if (name == "updateSymbol") {
      setSymbol(value);
      setUpdateSmbol(value);
    } else if (name == "from_symbol" || name == "to_symbol") {
      setSymbolMapping({ ...symbolMapping, [name]: value });
      setUpdatedAccountSyncData({
        ...updatedAccountSyncData,
        symbol_mappings: [
          ...accountSyncData.symbol_mappings,
          { ...symbolMapping, [name]: value },
        ],
      });
    } else {
      setAccountSyncData({ ...accountSyncData, [name]: value });
      setUpdatedAccountSyncData({ ...updatedAccountSyncData, [name]: value });
    }
  };

  const excludeSymbol = async (index, name) => {
    const excludedSymbols = accountSyncData?.excluded_symbols;
    let array;

    if (excludedSymbols) {
      if (excludedSymbols.includes(",")) {
        array = excludedSymbols.split(",").map((item) => item.trim());
        array.splice(index, 1);
      } else {
        array = [];
      }
    }
    setIsExcludeLoading(true);
    setShowSymbol(false);
    const response = await patchApiWithAuth(`accounts/${id}/strategies`, {
      ...updatedAccountSyncData,
      excluded_symbols: array.join(", "),
    });
    setIsExcludeLoading(false);

    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Data updated successfully");
    setAccountSyncData({
      ...accountSyncData,
      excluded_symbols: array.join(", "),
    });
  };

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `General`,
      children: (
        <AsyncGeneralSetting
          accountSyncData={accountSyncData}
          setAccountSyncData={setAccountSyncData}
          handleSyncSelectChange={handleSyncSelectChange}
          handleOnChange={handleOnChange}
          UpdatedAccountSync={UpdatedAccountSync}
        />
      ),
    },
    {
      key: "2",
      label: `Risk Settings`,
      children: (
        <AsyncRiskSetting
          accountSyncData={accountSyncData}
          setAccountSyncData={setAccountSyncData}
          handleSyncSelectChange={handleSyncSelectChange}
          handleOnChange={handleOnChange}
          UpdatedAccountSync={UpdatedAccountSync}
          tradeVolumeEnable={tradeVolumeEnable}
          tradeRiskEnable={tradeRiskEnable}
          updatedAccountSyncData={updatedAccountSyncData}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "3",
      label: `Stop & Limits`,
      children: (
        <AsyncStopAndLimit
          accountSyncData={accountSyncData}
          setAccountSyncData={setAccountSyncData}
          handleSyncSelectChange={handleSyncSelectChange}
          handleOnChange={handleOnChange}
          UpdatedAccountSync={UpdatedAccountSync}
          updatedAccountSyncData={updatedAccountSyncData}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "4",
      label: `Exclude Symbol`,
      children: (
        <AsyncSymbol
          accountSyncData={accountSyncData}
          setAccountSyncData={setAccountSyncData}
          handleSyncSelectChange={handleSyncSelectChange}
          handleOnChange={handleOnChange}
          excludeSymbol={excludeSymbol}
          updateSymbol={updateSymbol}
          setUpdateSmbol={setUpdateSmbol}
          UpdatedAccountSync={UpdatedAccountSync}
          updatedAccountSyncData={updatedAccountSyncData}
          isLoading={isLoading}
          isExcludeLoading={isExcludeLoading}
          setShowSymbol={setShowSymbol}
          showSymbol={showSymbol}
        />
      ),
    },
    {
      key: "5",
      label: `Map Symbol`,
      children: (
        <AsyncMapSymbol
          accountSyncData={accountSyncData}
          setAccountSyncData={setAccountSyncData}
          handleSyncSelectChange={handleSyncSelectChange}
          handleOnChange={handleOnChange}
          symbolMapping={symbolMapping}
          UpdatedAccountSync={UpdatedAccountSync}
          deleteMapSymbol={deleteMapSymbol}
          updatedAccountSyncData={updatedAccountSyncData}
          isLoading={isLoading}
          isDeleteLoading={isDeleteLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    getAccountSyncData();
  }, []);

  return (
    <>
      <div className="marketplaceListStyle">
        <div className="accountSettingHeadingContainerStyle">
          <div className="accountSyncSettingHeadingContainer">
            <div className="masterAccountHeading">
              Account Sync
              <sup>
                <img src={informationicon} />
              </sup>
            </div>
            <div className="masterLinkStyle">
              <u className="clickhereStyle">Click here </u>&nbsp; to learn About
              Account Syncing
            </div>
          </div>
        </div>
        <div className="accountSettingTabContainerMain">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="asyncSettingTabs"
          />
        </div>
      </div>
    </>
  );
};
export default AccountSyncSetting;
