import "./HomePageFeatureStyle.scss";
import logo from "assets/images/metaCopierLogo.svg";
import { useState } from "react";
import { MetaSyncButton } from "component/commonCompnent";
import HomePageMainImage from "assets/images/homePageImg.svg";

const HomePageFeature = () => {
  const [activeTab, setActiveTab] = useState(0);
  const handleClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className="homeourfeatureStyle">Our Features</div>
      <div className="homefeatureParaStyle">
        Lorem ipsum dolor sit amet,consecteturLorem ipsum dolor sit
        amet,consectetur
      </div>
      <div className="metaTraderCopierContainerStyle">
        <div className="copierLogoContainerStyle">
          <img src={logo} />
          <span className="metaCopierHeadingStyle">MetaTrader Copier</span>
        </div>
        <div>
          <nav style={{ margin: "2% 2% 1% 2%" }}>
            <div className="homeNavMainStyle">
              <div className="featureNavStyle">
                <div
                  className={
                    activeTab === 0
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(0)}
                >
                  <div>Fast Execution</div>
                </div>
                <div
                  className={
                    activeTab === 1
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(1)}
                >
                  <div>No VPS</div>
                </div>
                <div
                  className={
                    activeTab === 2
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(2)}
                >
                  <div>Prop Firm Friendly</div>
                </div>
                <div
                  className={
                    activeTab === 3
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(3)}
                >
                  <div>TP & SL Management</div>
                </div>
                <div
                  className={
                    activeTab === 4
                      ? "activeFeatureNav"
                      : "featurenavbarContantStyle"
                  }
                  onClick={() => handleClick(4)}
                >
                  <div> risk Management</div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="fastImageContainerStyle">
          <div className="fastExecutionContainerStyle">
            <div className="fastExecutionContentStyle">
              <div className="fastTextStyle">⚡Fast Execution</div>
              <div className="fastParaStyle">
                Users will be requested to add billing information and renew
                their account access there after. Users will be requested to add
                billing information and renew their account access there after.
                Users will be requested to add billing information and renew
                their account access there after.{" "}
              </div>
              <div>
                <MetaSyncButton text="View All Features" />
              </div>
            </div>
          </div>
          <div>
            <img
              src={HomePageMainImage}
              className="featureHomePageImageStyle"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageFeature;
