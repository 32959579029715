import { Radio, Space, Input, Select, message, Spin } from "antd";
import { handleIntegerInput } from "component/utilis/constant";
import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { patchApiWithAuth, getApiWithAuth } from "component/utilis/apiWrapper";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import informationicon from "assets/images/information-button.svg";
import "./EditMasterAccountRevampStyle.scss";
import { useLocation } from "react-router-dom";

const EditMasterAccountRevamp = () => {
  const [masterAccountData, setMasterAccoutData] = useState({
    account_number: "",
    account_name: "",
    account_password: "",
    account_type: "MT4",
    broker_name: "",
  });
  const [serverList, setServerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const location = useLocation();
  const { id, name } = location.state;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMasterAccoutData({ ...masterAccountData, [name]: value });
    setUpdatedData({ ...updatedData, [name]: value });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const getMasterAccoutById = async () => {
    const response = await getApiWithAuth(`accounts/${id}/get-master-account`);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setMasterAccoutData(response.data);
  };

  const updateMasterAccount = async () => {
    setIsLoading(true);

    const response = await patchApiWithAuth(
      `accounts/${id}/update-master-account/`,
      updatedData
    );
    if (!response.success) {
      setIsLoading(false);
      message.error(`${response.message.data.error}`);
      return;
    }
    setIsLoading(false);
    message.success("Data updated Successfully");
  };

  useEffect(() => {
    getMasterAccoutById();
  }, []);
  return (
    <>
      <div className="marketplaceListStyle">
        <div className="editMasterHeading">
          <div className="masterAccountHeading">
            Master Account
            <sup>
              <img src={informationicon} className="titleInformationIcon" />
            </sup>
          </div>
          <div className="masterLinkStyle">
            <u className="clickhereStyle">Click here </u>&nbsp; to learn about
            the master account page
          </div>
        </div>
        <div className="tableBoxStyle">
          <div className="createMasterAccoutTextStyle">Edit Master Account</div>
          <div className="masterRevampSecondContainer">
            <div className="accountContainerRevampStyle">
              <div className="AccoutTypeStyle">Account Type:</div>

              <div className="AccoutTypeRadioStyle">
                <Radio.Group
                  onChange={handleOnChange}
                  value={masterAccountData.account_type}
                  name="account_type"
                  disabled
                >
                  <Space
                    direction="horizontal"
                    className="createMasterRevampRadio"
                  >
                    <div>
                      <Radio value={"MT4"}>Metatrader 4(MT4)</Radio>
                    </div>
                    <div>
                      <Radio value={"MT5"}>Metatrader 5(MT5)</Radio>
                    </div>
                  </Space>
                </Radio.Group>
              </div>
            </div>
            <div className="masterinputFieldContainerRevampRight">
              <div className="AccoutTypeStyle">Account Name:</div>
              <div>
                <Input
                  placeholder="Type here in the box"
                  className="masterAccountInputStyle"
                  name="account_name"
                  value={masterAccountData.account_name}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="masterinputFieldContainerRevampRight">
              <div className="AccoutTypeStyle">Account Number:</div>
              <div>
                <Input
                  placeholder="Type here in the box"
                  className="masterAccountInputStyle"
                  name="account_number"
                  value={masterAccountData.account_number}
                  onChange={handleOnChange}
                  onInput={handleIntegerInput}
                  disabled
                />
              </div>
            </div>
            <div className="masterinputFieldContainerRevampRight">
              <div className="AccoutTypeStyle">Account Password:</div>
              <div>
                <Input
                  placeholder="Type here in the box"
                  className="masterAccountInputStyle"
                  name="account_password"
                  value={masterAccountData.account_password}
                  onChange={handleOnChange}
                  disabled
                />
              </div>
            </div>
            <div className="brokenConfigRevampStyle">
              <div>
                <div className="AccoutTypeStyle">Broker Server:</div>
                <div>
                  <Select
                    placeholder="Select Broker Server"
                    className="custom-select-broker"
                    name="broker_name"
                    style={{
                      width: "80%",
                      marginTop: "2%",
                    }}
                    value={masterAccountData.broker_name}
                    disabled
                  >
                    {isLoading ? (
                      <Select.Option>
                        <Spin indicator={antIcon} />
                      </Select.Option>
                    ) : (
                      serverList?.map((server) => (
                        <Select.Option key={server} value={server}>
                          {server}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                </div>
              </div>

              <div className="editMasterRevampButtonContainer">
                <div>
                  <MetaSyncButton
                    text="Edit"
                    className="masterCreateEditrevampButtonStyle"
                    onClick={() => updateMasterAccount()}
                    isLoading={isLoading}
                    disabled={Object.keys(updatedData).length === 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditMasterAccountRevamp;
