import { Carousel, Row, Col, Card, Button, Typography } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import companyLogo from "assets/images/companyLogo.svg";
import ProfilePicture from "assets/images/profilePicture.webp";
import { useRef } from "react";
import "./HomePageCarosoule.scss";

const HomePageCarosoule = () => {
  const carouselRef = useRef();
  const { Title, Paragraph } = Typography;
  const carouselData = [
    {
      id: 1,
      heading: "Heading 1",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
    {
      id: 2,
      heading: "Heading 2",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
    {
      id: 3,
      heading: "Heading 3",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
    {
      id: 4,
      heading: "Heading 4",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
    {
      id: 5,
      heading: "Heading 5",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
    {
      id: 6,
      heading: "Heading 6",
      text: `“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at. Ipsum
    suspendisse integer proin nunc.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aiadipiscing in at orci semper. Urna, urna, elit ac at."`,
    },
  ];

  const chunkedCarouselData = [];
  const chunkSize = 3;
  for (let i = 0; i < carouselData.length; i += chunkSize) {
    chunkedCarouselData.push(carouselData.slice(i, i + chunkSize));
  }

  const handleNext = () => {
    carouselRef.current.next();
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  return (
    <>
      <div className="trustedHeadingStyle">Trusted by over 50,000+ traders</div>
      <Carousel ref={carouselRef}>
        {chunkedCarouselData.map((chunk, index) => (
          <div key={index}>
            <Row gutter={16}>
              {chunk.map((item) => (
                <Col span={8} key={item.id}>
                  <div className="companyDivStyle">
                    <div className="companyContainerStyle">
                      <div>
                        <img src={companyLogo} />
                      </div>
                      <div className="companyNameStyle">Company</div>
                    </div>
                    <div className="AiParaStyle">{item.text}</div>
                    <div className="companyPortfolioStyle">
                      <div style={{ marginRight: "3%" }}>
                        <img
                          src={ProfilePicture}
                          className="companyPortfolioImageStyle"
                        />
                      </div>
                      <div>
                        <div className="companyPortfolioNameStyle">
                          Alicia Cia
                        </div>
                        <div className="AiParaStyle">
                          Head of Marketing at Google
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </Carousel>
      <div className="carasoleButtonStyle">
        <Button
          onClick={handlePrev}
          icon={
            <LeftCircleOutlined
              style={{ color: "#0059E7", fontSize: "20px" }}
            />
          }
          style={{ border: "none" }}
        />
        <Button
          onClick={handleNext}
          icon={
            <RightCircleOutlined
              style={{ color: "#0059E7", fontSize: "20px" }}
            />
          }
          style={{ border: "none" }}
        />
      </div>
    </>
  );
};
export default HomePageCarosoule;
