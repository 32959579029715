import React, { useState, useEffect } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Input, message } from "antd";
import "./AddPaymentCardStyle.scss";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { postApiWithAuth, patchApiWithAuth } from "component/utilis/apiWrapper";

const AddPaymentCard = ({
  setIsAddSubscription,
  billingDetailData,
  isUpdate,
  setIsModalPaidOpen,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    cardholder: "",
  });
  const [checkCard, setCheckCard] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });

  const onChangeHandle = (event) => {
    const { name, value } = event.target;

    setData({ ...data, [name]: value });
  };
  const onChangeHandleCard = (event) => {
    if (event.elementType === "cardNumber") {
      if (event.complete) {
        setCheckCard({
          ...checkCard,
          number: true,
        });
      } else {
        setCheckCard({
          ...checkCard,
          number: false,
        });
      }
    } else if (event.elementType === "cardExpiry") {
      if (event.complete) {
        setCheckCard({
          ...checkCard,
          expiry: true,
        });
      } else {
        setCheckCard({
          ...checkCard,
          expiry: false,
        });
      }
    } else if (event.elementType === "cardCvc") {
      if (event.complete) {
        setCheckCard({
          ...checkCard,
          cvc: true,
        });
      } else {
        setCheckCard({
          ...checkCard,
          cvc: false,
        });
      }
    }
  };

  const submitPaymentMethod = async (data, cardBrand, cardNumber) => {
    setIsLoading(true);
    var response;
    if (isUpdate) {
      response = await patchApiWithAuth(`payments/add-payment/`, {
        ...data,
        ...billingDetailData,
      });
    } else {
      response = await postApiWithAuth(`payments/add-payment/`, data);
    }
    if (!response.success) {
      setIsLoading(false);
      message.error(`${response.message.data.error}`);
      return;
    }
    setIsLoading(false);
    message.success("Payment has been completed");
    setIsAddSubscription(true);
    if (!isUpdate) {
      setIsModalPaidOpen(false);
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      // return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    let cardElement = null;
    cardElement = { ...elements.getElement(CardCvcElement) };
    cardElement = { ...cardElement, ...elements.getElement(CardExpiryElement) };
    cardElement = { ...cardElement, ...elements.getElement(CardNumberElement) };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const response = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      message.error(`${response.error.code}`);
    } else {
      const data = {
        payment_id: paymentMethod.id,
      };
      const cardBrand = paymentMethod.card.brand;
      const cardNumber = paymentMethod.card.last4;
      submitPaymentMethod(data, cardBrand, cardNumber);
    }
  };

  return (
    <>
      <div>
        <div className="createMasterAccoutTextStyle">Card Details</div>
        <form>
          <div className="cardNumberStyle">
            <div className="cardPaymentContainer">
              <div className="AccoutTypeStyle">Card Number</div>
              <CardNumberElement
                className="stripeInputField"
                onChange={onChangeHandleCard}
              />
            </div>
            <div className="cardPaymentContainer">
              <div className="AccoutTypeStyle">Card Holder Name</div>
              <div style={{ paddingTop: "3px" }}>
                <Input
                  placeholder="John Smith"
                  name="symbol"
                  onChange={onChangeHandle}
                  className="cardHolderNameStyle"
                  style={{ height: "44px" }}
                />
              </div>
            </div>
          </div>
          <div className="cardNumberStyle">
            <div className="cardPaymentContainer">
              <div className="AccoutTypeStyle">MM/YY</div>
              <CardExpiryElement
                className="stripeInputField"
                onChange={onChangeHandleCard}
              />
            </div>
            <div className="cardPaymentContainer">
              <div className="AccoutTypeStyle">CVC</div>
              <CardCvcElement
                className="stripeInputField"
                onChange={onChangeHandleCard}
              />
            </div>
          </div>
          <div className="metaButtonPyamentCrad">
            <MetaSyncButton
              text="Confirm Payment"
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </>
  );
};
export default AddPaymentCard;
