import "./HomePageMetaAdvanceStyle.scss";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import novps from "assets/images/noVps.svg";
import risk from "assets/images/riskImage.svg";
import prop from "assets/images/propImage.svg";
import advance from "assets/images/advancedImage.svg";

const HomePageMetaAdvance = () => {
  return (
    <>
      <div className="metaAdvanceMainStyle">
        <div className="AiContainerStyle">
          <div className="AiHeadingStyle">The MetaSync Advanced Features</div>
          <div className="AiParaStyle">
            Lorem ipsum dolor sit amet,consecteturLorem ipsum dolor sit
            amet,consectetur, Lorem ipsum dolor sit amet,consecteturLorem ipsum
            dolor sit amet,consectetur
          </div>
          <div className="trailButtonContainer">
            <div>
              <MetaSyncButton
                text="Learn more"
                className="freeTrialButtonStyle"
              />
            </div>
          </div>
        </div>
        <div className="advanceSecContainerStyle">
          <div className="vpsStyle">
            <div className="vpsContainerStyle">
              <img src={novps} />
              <div className="vpsHeadingStyle">No VPS Required</div>
              <div className="vpsParaStyle">No VPS Required</div>
            </div>
            <div className="vpsContainerStyle">
              <img src={risk} />
              <div className="vpsHeadingStyle">Risk Management</div>
              <div className="vpsParaStyle">
                Choose the appropriate risk management from our range
              </div>
            </div>
          </div>
          <div className="vpsStyle">
            <div className="vpsContainerStyle">
              <img src={prop} />
              <div className="vpsHeadingStyle">Prop Firm Friendly</div>
              <div className="vpsParaStyle">
                Cum sociis natoque penatibus et magnis dis parturient{" "}
              </div>
            </div>
            <div className="vpsContainerStyle">
              <img src={advance} />
              <div className="vpsHeadingStyle">Advanced Analytics</div>
              <div className="vpsParaStyle">
                Montes, nascetur ridiculus mus. Donec quam felis,{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePageMetaAdvance;
