import "./ListMarketPlaceStyle.scss";
import { Space, Table, Tag, Spin, Input, message } from "antd";
import { MetaSyncButton } from "component/commonCompnent";
import informationicon from "assets/images/information-button.svg";
import { useNavigate } from "react-router-dom";
import csvIcon from "assets/images/csvDownloadIcon.svg";
import deleteIcon from "assets/images/tableDeleteIcon.svg";
import listTable from "assets/images/ListTable.svg";
import CreateMasterAccountRevamp from "../CreateMasterAccountRevamp";
import { getApiWithAuth, deleteApiWithAuth } from "component/utilis/apiWrapper";
import listIcon from "assets/images/tableListIcon.svg";

import {
  LoadingOutlined,
  ArrowLeftOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import checkedGreen from "assets/images/checkedGreen.svg";

const ListMarketPlace = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListId, setIsListId] = useState(0);
  const [isListAccountName, setIsListAccountName] = useState(0);
  const [isListModal, setIsListModal] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [masterAccountData, setMasterAccoutData] = useState([]);
  const [selectedItem, setselectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { Search } = Input;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const searchOnchange = (event) => {
    setSearchValue(event.target.value);
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setselectedItem(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteMasterAccount();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteMasterAccount = async () => {
    setIsLoading(true);
    const response = await deleteApiWithAuth(
      `accounts/${selectedItem}/delete-master-account`
    );
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Deleted Succesfully");
    getMasterAccount();
  };
  const getMasterAccount = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth("accounts/get-master-accounts");
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setMasterAccoutData(response?.data);
  };
  const getFilterMasterAccount = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth(
      `accounts/get-master-accounts?search=${searchValue}`
    );
    setIsLoading(false);
    if (response.success) {
      setMasterAccoutData(response?.data);
    }
  };
  useEffect(() => {
    getMasterAccount();
  }, [isCreated]);

  const columns = [
    {
      title: <span className="customColumnHeaderStyle">Account Name</span>,
      dataIndex: "accountname",
      key: "accountname",
    },
    {
      title: <span className="customColumnHeaderStyle">Account Number</span>,
      dataIndex: "accountnumber",
      key: "accountnumber",
    },
    {
      title: <span className="customColumnHeaderStyle">Broker Server</span>,
      dataIndex: "brokerserver",
      key: "brokerserver",
    },
    {
      title: <span className="customColumnHeaderStyle">Balance</span>,
      key: "balance",
      dataIndex: "balance",
    },
    {
      title: <span className="customColumnHeaderStyle">Deposit</span>,
      key: "deposit",
      dataIndex: "deposit",
    },
    {
      title: <span className="customColumnHeaderStyle">Withdrawals</span>,
      key: "withdrawals",
      dataIndex: "withdrawals",
    },
    {
      title: <span className="customColumnHeaderStyle">Account Type</span>,
      key: "accountType",
      dataIndex: "accountType",
    },
    {
      title: <span className="customColumnHeaderStyle">Status</span>,
      key: "status",
      dataIndex: "status",
    },
    {
      title: <span className="customColumnHeaderStyle">Options</span>,
      key: "options",
      dataIndex: "options",
    },
  ];

  useEffect(() => {
    if (masterAccountData) {
      const data = masterAccountData.map((item) => ({
        accountname: (
          <span className="customRowStyle">{item.account.account_name}</span>
        ),
        accountnumber: (
          <span className="customRowStyle">{item.account.account_number}</span>
        ),
        brokerserver: (
          <span className="customRowStyle">{item.account.broker_name}</span>
        ),
        balance: <span className="customRowStyle">{item.stats.Balance}</span>,
        deposit: <span className="customRowStyle">{item.stats.Deposits}</span>,
        status: (
          <span className="customRowStyle">
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {item.stats.Status === "Connected" ? (
                    <img
                      src={checkedGreen}
                      width={15}
                      height={15}
                      style={{
                        paddingTop: "9px",
                        paddingRight: "5px",
                      }}
                    />
                  ) : (
                    <div style={{ paddingRight: "5px" }}>
                      <CloseCircleFilled style={{ color: "red" }} />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    color:
                      item.stats.Status === "Connected" ? "#61DE7E" : "red",
                  }}
                >
                  {item.stats.Status}
                </div>
              </div>
            </>
          </span>
        ),
        withdrawals: (
          <span className="customRowStyle">{item.stats.Withdrawals}</span>
        ),
        accountType: (
          <span className="customRowStyle">{item.account.account_type}</span>
        ),

        connectedaccounts: (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                {item.stats.Status === "Connected" ? (
                  <img
                    src={checkedGreen}
                    width={15}
                    height={15}
                    style={{
                      paddingTop: "9px",
                      paddingRight: "5px",
                    }}
                  />
                ) : (
                  <div style={{ paddingRight: "5px" }}>
                    <CloseCircleFilled style={{ color: "red" }} />
                  </div>
                )}
              </div>
              <div
                style={{
                  color: item.stats.Status === "Connected" ? "#61DE7E" : "red",
                }}
              >
                {item.stats.Status}
              </div>
            </div>
          </>
        ),
        options: (
          <>
            <div>
              <img
                src={listIcon}
                width={15}
                height={15}
                onClick={() =>
                  navigate("/editMasterAccount", {
                    state: {
                      id: item.account.id,
                      name: item.account.account_name,
                    },
                  })
                }
                style={{
                  cursor: "pointer",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              />
              <img
                src={deleteIcon}
                width={15}
                height={15}
                onClick={() => showModal(item.account.id)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        ),
      }));
      setTableData(data);
    }
  }, [masterAccountData]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };
  return (
    <>
      <div className="marketplaceListStyle">
        <div className="masterHeadingContainerStyle">
          <div className="backMasterContaierStyle">
            {isCreated ? (
              <div>
                <MetaSyncButton
                  text={
                    <>
                      <ArrowLeftOutlined />
                    </>
                  }
                  onClick={() => setIsCreated(false)}
                  className="backButtonStyle"
                />
              </div>
            ) : (
              ""
            )}
            <div>
              <div className="masterAccountHeading">
                Master ACCOUNTS
                <sup>
                  <img src={informationicon} className="titleInformationIcon" />
                </sup>
              </div>
              <div className="masterLinkStyle">
                <u className="clickhereStyle">Click here </u>&nbsp; to learn
                about the master account page
              </div>
            </div>
          </div>
          <div>
            {!isCreated ? (
              <div>
                <MetaSyncButton
                  text="Add Master Account"
                  onClick={() => setIsCreated(true)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!isCreated ? (
          isLoading ? (
            <div className="tableListBoxStyle">
              <Spin
                indicator={antIcon}
                size="large"
                centered
                className="spinClass"
              />
            </div>
          ) : (
            <div className="tableListBoxStyle">
              <Table
                columns={columns}
                dataSource={tableData}
                className="listTableStyle"
                scroll={{
                  y: 240,
                }}
                pagination={{
                  pageSize: 5,
                  position: ["bottomRight"],
                  size: "default",
                  itemRender: itemRender,
                }}
                title={() => (
                  <>
                    <div className="billingHeaderStyle">
                      <div className="tableHeaderStyle">
                        Account Connections
                        <sup>
                          <img
                            src={informationicon}
                            alt="information icon"
                            className="titleInformationIcon"
                          />
                        </sup>
                      </div>
                      <div>
                        <Search
                          placeholder="Search"
                          onSearch={getFilterMasterAccount}
                          enterButton
                          name="searchValue"
                          value={searchValue}
                          onChange={searchOnchange}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            </div>
          )
        ) : (
          <div className="tableBoxStyle">
            <CreateMasterAccountRevamp setIsCreated={setIsCreated} />
          </div>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        okText={"Yes"}
        cancelText={"No"}
        className="centered-modal-footer"
      >
        <div className="listDeleteStyle">
          Are you sure you want to delete this account?
        </div>
      </Modal>
    </>
  );
};
export default ListMarketPlace;
