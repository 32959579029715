import { Radio, message } from "antd";
import meta from "assets/images/metaPayment.svg";
import other from "assets/images/otherPayment.svg";
import BlueTick from "assets/images/metaBlueTick.svg";
import WhiteTick from "assets/images/metaWhiteTick.svg";
import { MetaSyncButton } from "component/commonCompnent";
import {
  getApiWithoutAuth,
  postApiWithAuth,
} from "component/utilis/apiWrapper";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { setIsPaid } from "component/utilis/localStorage";
import "./SubscriptionStyle.scss";
import Billing from "../Billing";

const Subscription = ({ setIsModalPaidOpen }) => {
  const [plans, setPlans] = useState(0);
  const [duration, setDuration] = useState("monthly");
  const [priceId, setPriceId] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [isAddSubscription, setIsAddSubscription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(1);

  const onChangeRadio = (e) => {
    setValue(e.target.value);
    if (e.target.value === 2) {
      setDuration("yearly");
    } else {
      setDuration("monthly");
    }
  };

  const getPricing = async () => {
    const response = await getApiWithoutAuth(
      `payments/get-subscriptions?duration=${duration}`
    );
    setPlans(response.data.data);
  };

  const AddSubscription = async () => {
    setIsLoading(true);
    const response = await postApiWithAuth(`payments/subscribe-customer/`, {
      price_id: priceId,
      subscription_type: "platform",
    });
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setIsPaid(true);
  };

  useEffect(() => {
    getPricing();
  }, [duration]);

  useEffect(() => {
    if (isAddSubscription) {
      AddSubscription();
    }
  }, [isAddSubscription]);

  return (
    <>
      {!isStarted ? (
        <>
          <div className="monthContainerStyle">
            <Radio.Group
              onChange={onChangeRadio}
              value={value}
              style={{ display: "flex" }}
            >
              <Radio value={1} className="montlyStyle">
                monthly
              </Radio>
              <Radio value={2} className="montlyStyle">
                Annual
              </Radio>
            </Radio.Group>
          </div>

          <div className="planDivStyle">
            <div
              className="planPaidContainerStyle"
              onClick={() => setPriceId(plans[1]?.id)}
            >
              <div>
                <div className="metaPlanImageCotainer">
                  <div>
                    <img src={meta} />
                  </div>
                  <div className="metaCopierPlan">
                    <div className="montlyStyle">Metatrader Copier</div>
                    <div className="montlyStyle">
                      ${plans[0]?.amount / 100}
                      <span className="permonthStyle">
                        {duration == "monthly" ? "/month" : "/year"}
                      </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="mailServiceStyle">
                    <div>Mail Service Auth</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Users Google Form</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Responsive Template</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Bulk Upload Images</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Grid Automatically On</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Unlimited Links</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>Export to Sheets</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                  <div className="mailServiceStyle">
                    <div>24/7 Customer Support</div>
                    <div>
                      <img src={BlueTick} />
                    </div>
                  </div>
                </div>
                <MetaSyncButton
                  text="Get Started"
                  className="planGetStartedButtonStyle"
                  onClick={() => setIsStarted(true)}
                />
              </div>
            </div>
            {/* <div className="planContainerStyle">
          <div>
            <div className="metaPlanImageCotainer">
              <div>
                <img src={other} />
              </div>
              <div className="metaCopierPlan">
                <div className="montlytelegramStyle">Telegram Copier</div>
                <div className="montlytelegramStyle">
                  ${plans[1]?.amount / 100}
                  <span className="permonthStyle">
                    {duration === "monthly" ? "/month" : "/year"}
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="mailServiceStyle">
                <div>Mail Service Auth</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Users Google Form</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Responsive Template</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Bulk Upload Images</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Grid Automatically On</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Unlimited Links</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>Export to Sheets</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
              <div className="mailServiceStyle">
                <div>24/7 Customer Support</div>
                <div>
                  <img src={WhiteTick} />
                </div>
              </div>
            </div>
            <MetaSyncButton
              text="Get Started"
              className="planTelegramGetStartedButton"
            />
          </div>
        </div> */}
          </div>
        </>
      ) : (
        <Billing
          setIsAddSubscription={setIsAddSubscription}
          isUpdate={false}
          setIsModalPaidOpen={setIsModalPaidOpen}
        />
      )}
    </>
  );
};
export default Subscription;
