import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Button } from "antd";
import "./metaSyncButtonStyle.scss";
const metaSyncButton = (props) => {
  return (
    <>
      <Button
        type="primary"
        loading={props.isLoading}
        onClick={props.onClick}
        className={props.className ? props.className : "metaButtonStyle"}
        icon={props.icon ? <img src={props.icon} /> : ""}
        disabled={props.disabled}
      >
        {props.text}
      </Button>
    </>
  );
};
export default metaSyncButton;
