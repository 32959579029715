import { useEffect } from "react";
import "./EditUserProfileStyle.scss";
import { useDropzone } from "react-dropzone";
import { Input, message } from "antd";
import { useCallback, useState, useMemo } from "react";
import GalleryIcon from "assets/images/galleryIcon.svg";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { patchApiWithAuth, getApiWithAuth } from "component/utilis/apiWrapper";
import { useAuth0 } from "@auth0/auth0-react";

const EditUserAccount = ({ updateUserDataNav, setUpdateUserDataNav }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [updateUser, setUpdateUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth0();
  const [userProfile, setUserProfile] = useState({});
  const userProfileData = async () => {
    const response = await getApiWithAuth("users/get-profile");
    if (!response.success) {
      return;
    }
    setUpdateUser({});
    setUserProfile(response.data);
  };

  useEffect(() => {
    userProfileData();
  }, [updateUserDataNav]);

  const [uploadStatus, setUploadStatus] = useState("");
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedImages([acceptedFiles[0]]);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const onUpload = async () => {
    setUploadStatus("Uploading....");
    try {
      setUpdateUser({ ...updateUser, picture: selectedImages[0] });
      setUploadStatus("Image uploaded successfully");
    } catch (error) {
      setUploadStatus("Upload failed");
    }
  };

  const style = useMemo(
    () => ({
      ...(isDragAccept ? { borderColor: "#00e676" } : {}),
      ...(isDragReject ? { borderColor: "#ff1744" } : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setUpdateUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const UpdateUserProfile = async () => {
    const formData = new FormData();
    for (const key in updateUser) {
      formData.append(key, updateUser[key]);
    }
    setIsLoading(true);
    const response = await patchApiWithAuth("users/update-profile", formData);
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Profile updated successfully");

    setUpdateUserDataNav(!updateUserDataNav);
  };

  useEffect(() => {
    if (selectedImages.length > 0) {
      onUpload();
    }
  }, [selectedImages]);

  return (
    <>
      <div className="EditUserProfileStyle">
        <div>
          <div className="masterAccountHeading">My Profile</div>
          <hr />
          <div className="personalInfoStyle">Personal Info</div>
          <div className="feelFreeTextStyle">
            Update your Photo and Personal details here!
          </div>
          <div className="masterinputFieldContainer">
            <div className="masterinputFieldContainerLeft">
              <div className="contactTagStyle">Name:</div>
              <div>
                <Input
                  placeholder={userProfile?.nickname}
                  className="masterAccountInputStyle"
                  name="nickname"
                  value={updateUser?.nickname}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className="masterinputFieldContainerRight">
              <div className="contactTagStyle">Change Password:</div>
              <div>
                <Input.Password
                  placeholder="Enter your password"
                  className="masterAccountInputStyle"
                  name="password"
                  value={updateUser?.password}
                  onChange={handleOnChange}
                  type="password"
                />
              </div>
            </div>
          </div>
          <div className="personalInfoStyle">Your Photo</div>
          <div className="feelFreeTextStyle">
            This will be displayed on your Profile.
          </div>
        </div>
        <div>
          <div
            className="dropZoneContainerStyle"
            {...getRootProps({ style })}
            style={{ cursor: "pointer" }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop file(s) here ...</p>
            ) : (
              <div className="clickHereContainerStyle">
                <img src={GalleryIcon} />
                <div className="clickHereStyle">
                  Click to upload or drag and drop
                  <br /> SVG, PNG, JPG or GIF (max. 5MB)
                </div>
              </div>
            )}
          </div>
          <div className="uploadImageContainerStyle">
            <div>
              {selectedImages.length > 0 &&
                selectedImages.map((image, index) => (
                  <img
                    src={`${URL.createObjectURL(image)}`}
                    key={index}
                    alt=""
                    className="imageContainerStyle"
                  />
                ))}
            </div>
            {/* {selectedImages.length > 0 && (
              <div>
                <MetaSyncButton
                  onClick={onUpload}
                  text="Upload"
                  className="uploadButtonStyle"
                />
                <p>{uploadStatus}</p>
              </div>
            )} */}
          </div>
        </div>
        <MetaSyncButton
          text="Save"
          onClick={UpdateUserProfile}
          isLoading={isLoading}
          className="uploadSaveButton"
        />
      </div>
    </>
  );
};
export default EditUserAccount;
