import MetaImage from "assets/images/metaImage.svg";
import LionImage from "assets/images/lionImage.svg";
import MetaFrame from "assets/images/metaFrame.svg";
import Meta from "assets/images/Meta.svg";
import LionFrame from "assets/images/lionFrame.svg";
import Speed from "assets/images/speedImage.svg";
import SnailImage from "assets/images/snailImage.svg";
import "./HomePagePlatformStyle.scss";

const HomePagePlatform = () => {
  return (
    <>
      <div className="homeourfeatureStyle">MetaSync vs Other platforms</div>
      <div className="homefeatureParaStyle">
        Lorem ipsum dolor sit amet,consecteturLorem ipsum dolor sit
        amet,consectetur
      </div>
      <div className="platformMainContainerStyle">
        <div className="platformMetaContainerStyle">
          <div>
            <img src={Meta} width={117} alt="Meta Logo" />
          </div>
          <div>
            <img src={MetaFrame} alt="Meta Frame" />
          </div>
          <div className="speedContainerStyle">
            <img src={Speed} alt="Meta Speed" />
            <span className="minStyle">3 min</span>
          </div>
        </div>
        <div class="vl"></div>
        <div className="platformLionContainerStyle">
          <div>
            <img src={LionImage} width={117} alt="Lion Image" />
          </div>
          <div>
            <img src={LionFrame} width="400px" alr="Lion Frame" />
          </div>
          <div className="speedContainerStyle">
            <img src={SnailImage} alt="Snail Image" />
            <span className="min30style">30+ Minutes</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePagePlatform;
