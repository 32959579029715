import "./LoadingComponentStyle.scss";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { setToken, setIsPaid } from "component/utilis/localStorage";
import { getApiWithAuth } from "component/utilis/apiWrapper";

const LoadingComponent = () => {
  const navigate = useNavigate();
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    if (isAuthenticated === true) {
      const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUDIENCE,
            scope: "read:current_user update:users",
          });
          setToken(accessToken);
          const response = await getApiWithAuth(
            "payments/check-active-platform-subscription"
          );
          setIsPaid(response.data.has_active_platform_subscription);
          navigate("/dashboard");
        } catch (e) {
          console.log(e.message);
        }
      };
      getUserMetadata();
    }
  }, [user?.sub, getAccessTokenSilently]);
  return (
    <>
      <div className="loadingComponentStyle">
        <div class="loader"></div>
      </div>
    </>
  );
};
export default LoadingComponent;
