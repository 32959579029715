export const handleIntegerInput = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const handleNumericInput = (e) => {
  const input = e.target.value;
  const regex = /^\d*[.]?\d*$/;
  if (!regex.test(input)) {
    e.target.value = input.slice(0, -1);
  }
};
export const autoDownloadFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
