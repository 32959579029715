import React, { useEffect, useState } from "react";
import HelloImg from "assets/images/Hello.svg";
import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import ChartState from "assets/images/chartstate.svg";
import GraphIcon from "assets/images/graphIcon.svg";
import Vector from "assets/images/Vector.svg";
import informationicon from "assets/images/information-button.svg";
import ReactApexChart from "react-apexcharts";
import { Table, Tabs, DatePicker, message, Spin, Select, Tooltip } from "antd";
import "./DashboardStyle.scss";
import { DownOutlined } from "@ant-design/icons";
import { getApiWithAuth } from "component/utilis/apiWrapper";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [connectedData, setConnectedData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [accountInitialData, setAccountInitialData] = useState("");
  const [accountDataId, setAccountDataId] = useState("");
  const [accountInfoId, setAccountInfoId] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [currentPipYear, setCurrentPipYear] = useState("");
  const [dashboardsData, setDashboardData] = useState({});
  const [accountInfo, setAccountInfo] = useState({});
  const [dashboardDefaultData, setDashboardDefaultData] = useState(false);
  const [dashboardImage, setDashboardImage] = useState("");
  const [tabs, setTabs] = useState("tab1");
  const [series, setSeries] = useState([
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const [seriesPip, setSeriesPip] = useState([
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
    setCurrentPipYear(year);
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const onChange = (date, dateString) => {
    setCurrentYear(dateString);
  };
  const onChangePip = (date, dateString) => {
    setCurrentPipYear(dateString);
  };

  const onChangeTab = (key) => {
    setTabs(key);
  };

  const accountDataList = async () => {
    const response = await getApiWithAuth("accounts/get-users-dashboard");
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setAccountData(response.data);
    setAccountInitialData(response.data[0].account_name);
    setAccountDataId(response?.data[0]?.account_id);
    setAccountInfoId(response?.data[0]?.id);
  };

  const dashboardData = async () => {
    const response = await getApiWithAuth(
      `accounts/${accountDataId}/get-dashboard-overall-stats?year=${currentYear}`
    );

    if (!response.success) {
      setDashboardDefaultData(true);
      return;
    }
    setDashboardData(response?.data?.stats);
    const data = Object.values(response.data.stats.monthly_gains_dict).map(
      (value) => Math.floor(value)
    );
    setSeries((prevState) => [
      {
        ...prevState[0],
        data: data,
      },
    ]);
  };

  const dashboardPipChartData = async () => {
    const response = await getApiWithAuth(
      `accounts/${accountDataId}/get-dashboard-pips-stats?year=${currentPipYear}`
    );
    const dataPip = Object.values(response.data.stats.monthly_pips_dict).map(
      (value) => Math.floor(value)
    );
    setSeriesPip((prevState) => [
      {
        ...prevState[0],
        data: dataPip,
      },
    ]);
  };

  const accountInformation = async () => {
    const response = await getApiWithAuth(
      `accounts/${accountInfoId}/get-dashboard-account-stats`
    );

    if (!response.success) {
      return;
    }
    setAccountInfo(response.data.meta_data);
  };
  const dashboardBanner = async () => {
    const response = await getApiWithAuth("accounts/get-dashboard-banner");
    if (!response.success) {
      return;
    }
    setDashboardImage(response?.data?.banner);
  };
  useEffect(() => {
    if (accountDataId) {
      dashboardData();
      accountInformation();
      dashboardPipChartData();
    }
  }, [accountDataId, currentYear]);
  useEffect(() => {
    if (accountDataId) {
      dashboardPipChartData();
    }
  }, [accountDataId, currentPipYear]);

  const columns = [
    {
      title: "Account Name",
      dataIndex: "accountname",
      key: "accountname",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Equity",
      dataIndex: "equity",
      key: "equity",
    },
    {
      title: "Percent",
      key: "percent",
      dataIndex: "percent",
    },
    {
      title: "Open Trades",
      key: "opentrade",
      dataIndex: "opentrade",
    },
    {
      title: "Pending",
      key: "pending",
      dataIndex: "pending",
    },
    {
      title: "Connected Master Account IDs",
      key: "masterId",
      dataIndex: "masterId",
    },
  ];
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next </a>;
    }
    return originalElement;
  };

  const getConnectedAccountData = async () => {
    setIsLoading(true);
    const response = await getApiWithAuth("accounts/get-dashboard-accounts");
    setIsLoading(false);
    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    setConnectedData(response?.data);
  };

  useEffect(() => {
    getConnectedAccountData();
    accountDataList();
    dashboardBanner();
  }, []);

  useEffect(() => {
    if (connectedData) {
      const data = connectedData.map((item) => ({
        accountname: item.account_name,
        balance: item.meta_data.balance,
        equity: item.meta_data.equity,
        percent: item.meta_data.equity_percent,
        opentrade: item.meta_data.orders,
        pending: item.meta_data.positions,
        masterId: item.meta_data?.connected_master_accounts?.length,
      }));
      setTableData(data);
    }
  }, [connectedData]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });
  const tabsData = [
    {
      label: <div style={{ fontWeight: "500" }}>Trading Statistics</div>,
      key: "tab1",
      data: {
        title: "Data for Tab 1",
        content: "This is the content for Tab 1",
      },
    },
    {
      label: <div style={{ fontWeight: "500" }}>Account Info</div>,
      key: "tab2",
      data: {
        title: "Data for Tab 2",
        content: "This is the content for Tab 2",
      },
    },
  ];

  function TabContent({ data }) {
    return (
      <>
        {tabs == "tab1" ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  {" "}
                  <Tooltip placement="topLeft" title={"Trades"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Trades:
                </div>
                <div>{dashboardsData?.trades}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Longs Won"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Longs Won:
                </div>
                <div>{dashboardsData?.longs_won}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Pips"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Pips
                </div>
                <div>{dashboardsData?.net_pips}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Shorts Won"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Shorts Won:
                </div>
                <div>{dashboardsData?.shorts_won}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Average Win"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Average Win:
                </div>
                <div>{dashboardsData?.average_win}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Avg. Trade Length"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Avg. Trade Length:
                </div>
                <div>{dashboardsData?.average_trade_length}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Average Loss"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Average Loss:
                </div>
                <div>{dashboardsData?.average_loss}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Commission"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Commission:
                </div>
                <div>{dashboardsData?.commissions}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Lots"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Lots:
                </div>
                <div>{dashboardsData?.lots}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Swaps"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Swaps:
                </div>
                <div>{dashboardsData?.swap}</div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Gain"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Gain
                </div>
                <div> {accountInfo?.gain}</div>
              </div>
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Kurtosis Score"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Kurtosis Score
                </div>
                <div>{accountInfo?.kurtosis_profit}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Margin Level"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Margin Level
                </div>
                <div> {accountInfo?.margin_level}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Net Pips"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Net Pips
                </div>
                <div> {accountInfo?.pips}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Profile Factor"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Profile Factor
                </div>
                <div> {accountInfo?.profit_factor}</div>
              </div>
              <div className="dashboardtradingTable">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"MAR"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  MAR
                </div>
                <div> {accountInfo?.mar}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="dashboardtradingTableleft">
                <div className="tabContentStyle">
                  <Tooltip placement="topLeft" title={"Sortino Ratio"}>
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </Tooltip>
                  Sortino Ratio
                </div>
                <div>{accountInfo?.sortino_ratio}</div>
              </div>
              <div className="dashboardtradingTable"></div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className="dashboardStyle">
        <div className="helloNameContainerStyle">
          <div>
            <div className="helloStyle">
              Hello, {accountInitialData}
              <span>
                <img src={HelloImg} alt="Hello" />
              </span>
            </div>
            <div className="AiParaStyle">
              Lorem ipsum is a placeholder text commonly used to demonstrate
            </div>
          </div>
          <div>
            <Select
              className="dashboardAccountName"
              value={accountInitialData}
              onChange={(value) => {
                const selectedServer = accountData.find(
                  (server) => server.id === value
                );
                setAccountDataId(selectedServer?.account_id);
                setAccountInitialData(selectedServer.account_name);
              }}
              style={{ width: "100%" }}
            >
              {accountData?.map((server) => (
                <Select.Option key={server.id} value={server.id}>
                  {server.account_name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        {accountDataId?.length == 0 || dashboardDefaultData ? (
          ""
        ) : (
          <div className="dashboardImageContainer">
            <img
              src={dashboardImage}
              width={"100%"}
              height={220}
              style={{ borderRadius: "12px" }}
            />
          </div>
        )}
        <div className="coinContainerStyle">
          <div className="coinStyle">
            <div className="accountBalFirstContainerStyle">
              <div>
                <img
                  src={GraphIcon}
                  alt="Coin"
                  width={"39px"}
                  style={{ marginRight: "2%" }}
                />
              </div>
              <div className="accountBalanceStyle">Account Bal</div>
              <div className="vectorIconStyle">
                <img src={Vector} alt="Coin" />
              </div>
            </div>
            <div className="accountBalContainerStyle">
              <div className="BalanceStyle">
                {accountDataId?.length === 0 ||
                dashboardDefaultData ||
                accountDataId == undefined
                  ? "$0"
                  : `$${dashboardsData?.account_balance?.toFixed(1)}`}
              </div>
            </div>
          </div>
          <div className="coinStyle">
            <div className="accountBalFirstContainerStyle">
              <div>
                <img
                  src={GraphIcon}
                  alt="Coin"
                  width={"39px"}
                  style={{ marginRight: "2%" }}
                />
              </div>
              <div className="accountBalanceStyle">Profit/Loss(in $)</div>
              <div className="vectorIconStyle">
                <img src={Vector} alt="Coin" />
              </div>
            </div>
            <div className="accountBalContainerStyle">
              <div className="BalanceStyle">
                {accountDataId?.length === 0 ||
                dashboardDefaultData ||
                accountDataId == undefined
                  ? "$0"
                  : `$${dashboardsData?.profit_loss_in_dollars?.toFixed(1)}`}
              </div>
            </div>
          </div>
          <div className="coinStyle">
            <div className="accountBalFirstContainerStyle">
              <div>
                <img
                  src={GraphIcon}
                  alt="Coin"
                  width={"39px"}
                  style={{ marginRight: "2%" }}
                />
              </div>
              <div className="accountBalanceStyle">Profit/Loss(in %)</div>
              <div className="vectorIconStyle">
                <img src={Vector} alt="Coin" />
              </div>
            </div>
            <div className="accountBalContainerStyle">
              <div className="BalanceStyle">
                {accountDataId?.length === 0 ||
                dashboardDefaultData ||
                accountDataId == undefined
                  ? "0%"
                  : `${dashboardsData?.profit_loss_in_percent?.toFixed(1)}%`}
              </div>
            </div>
          </div>
          <div className="coinStyle">
            <div className="accountBalFirstContainerStyle">
              <div>
                <img
                  src={GraphIcon}
                  alt="Coin"
                  width={"39px"}
                  style={{ marginRight: "2%" }}
                />
              </div>
              <div className="accountBalanceStyle">Net Pips</div>
              <div className="vectorIconStyle">
                <img src={Vector} alt="Coin" />
              </div>
            </div>
            <div className="accountBalContainerStyle">
              <div className="BalanceStyle">
                {accountDataId?.length === 0 ||
                dashboardDefaultData ||
                accountDataId == undefined
                  ? "0"
                  : `$${dashboardsData?.net_pips?.toFixed(1)}`}
              </div>
            </div>
          </div>
        </div>
        <div className="graphContainer">
          <div id="chart" className="graphContainerStyle">
            <div className="graphHeadingStyle">
              <div className="totalGrowthStyle">Total Growth</div>
              <DatePicker
                onChange={onChange}
                picker="year"
                className="dashboardDatePickerStyle"
                suffixIcon={<DownOutlined style={{ color: "#1C8BFE" }} />}
                placeholder={currentYear}
              />
            </div>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={350}
            />
          </div>
          <div id="chart" className="graphContainerStyle">
            <div className="graphHeadingStyle">
              <div className="totalGrowthStyle">Net Pips</div>
              <DatePicker
                onChange={onChangePip}
                picker="year"
                className="dashboardDatePickerStyle"
                suffixIcon={<DownOutlined style={{ color: "#1C8BFE" }} />}
                placeholder={currentYear}
              />
            </div>
            <ReactApexChart
              options={options}
              series={seriesPip}
              type="line"
              height={350}
            />
          </div>
        </div>
        <div className="tabStyle">
          <Tabs
            onChange={onChangeTab}
            type="card"
            items={tabsData.map((tab) => ({
              label: tab.label,
              key: tab.key,
              children: <TabContent data={tab.data} />,
            }))}
            className="dashboardTabStyle"
          />
        </div>
        <div className="masterHeadingContainerStyle">
          <div>
            <div className="masterAccountHeading">
              Connected Accounts
              <sup>
                <img
                  src={informationicon}
                  alt="Info"
                  className="titleInformationIcon"
                />
              </sup>
            </div>
            <div className="masterLinkStyle">
              <u className="clickhereStyle">Click here </u>&nbsp; to learn about
              the connected account page
            </div>
          </div>
          <div>
            <div>
              <MetaSyncButton
                text="Add Subscriber Account"
                onClick={() => navigate("/createUserAccount")}
              />
            </div>
          </div>
        </div>

        <div className="dashboardTableContainer">
          {isLoading ? (
            <Spin
              indicator={antIcon}
              size="large"
              centered
              className="spinClass"
            />
          ) : (
            <Table
              columns={columns}
              dataSource={tableData}
              className="listTableStyle"
              scroll={{
                y: 240,
              }}
              pagination={{
                pageSize: 5,
                position: ["bottomRight"],
                size: "default",
                itemRender: itemRender,
              }}
              title={() => (
                <>
                  <div className="tableHeaderStyle">
                    Account Connections
                    <sup>
                      <img
                        src={informationicon}
                        alt="Info"
                        className="titleInformationIcon"
                      />
                    </sup>
                  </div>
                </>
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
