import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  HomePageNavbar,
  HomePageFooter,
  LoadingComponent,
} from "component/commonCompnent";
import {
  Dashboard,
  ListMarketPlace,
  UserAccount,
  AccountSync,
  SubscriberTradeMimic,
  BillingComponent,
  HomePage,
  HomePageFeature,
  MetaPricing,
  MetaContact,
  MetaFaq,
  ContactUs,
  Billing,
  TermAndCondition,
  EditUserProfile,
  CreateMasterAccountRevamp,
  EditMasterAccountRevamp,
  EditSubscriberAccountRevamp,
  AccountSyncRevamp,
  AccountSyncSetting,
} from "component/mainComponent";
import PrivateRoute from "component/routes/PrivateRoute";
import PublicRoute from "component/routes/PublicRoute";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/homenav" element={<HomePageNavbar />} />
          <Route path="/homefooter" element={<HomePageFooter />} />
          <Route path="/loading" element={<LoadingComponent />} />
          <Route path="/pricing" element={<MetaPricing />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/faq" element={<MetaFaq />} />
          <Route path="/contact" element={<MetaContact />} />
          <Route path="/homefeature" element={<HomePageFeature />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute sidebar={true}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/billings"
          element={
            <PrivateRoute sidebar={false}>
              <Billing />
            </PrivateRoute>
          }
        />
        <Route
          path="/accountsyncSetting"
          element={
            <PrivateRoute sidebar={true}>
              <AccountSyncSetting />
            </PrivateRoute>
          }
        />
        <Route
          path="/createMasterAccount"
          element={
            <PrivateRoute sidebar={true}>
              <CreateMasterAccountRevamp />
            </PrivateRoute>
          }
        />

        <Route
          path="/contactUs"
          element={
            <PrivateRoute sidebar={true}>
              <ContactUs />
            </PrivateRoute>
          }
        />
        <Route
          path="/createUserAccount"
          element={
            <PrivateRoute sidebar={true}>
              <UserAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/marketplaceList"
          element={
            <PrivateRoute sidebar={true}>
              <ListMarketPlace />
            </PrivateRoute>
          }
        />

        <Route
          path="/editMasterAccount"
          element={
            <PrivateRoute sidebar={true}>
              <EditMasterAccountRevamp />
            </PrivateRoute>
          }
        />
        <Route
          path="/editUserAccount"
          element={
            <PrivateRoute sidebar={true}>
              <EditSubscriberAccountRevamp />
            </PrivateRoute>
          }
        />
        <Route
          path="/accountSync"
          element={
            <PrivateRoute sidebar={true}>
              <AccountSync />
            </PrivateRoute>
          }
        />
        <Route
          path="/editUserProfile"
          element={
            <PrivateRoute sidebar={true}>
              <EditUserProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/termAndCondition"
          element={
            <PrivateRoute sidebar={true}>
              <TermAndCondition />
            </PrivateRoute>
          }
        />
        <Route
          path="/createAccountSync"
          element={
            <PrivateRoute sidebar={true}>
              <AccountSyncRevamp />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscriberTradeMimic"
          element={
            <PrivateRoute sidebar={true}>
              <SubscriberTradeMimic />
            </PrivateRoute>
          }
        />

        <Route
          path="/billingDetails"
          element={
            <PrivateRoute sidebar={true}>
              <BillingComponent />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
