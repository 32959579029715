import MetaSyncButton from "component/commonCompnent/metaSyncButton";
import { message, Input } from "antd";
import { postApiWithoutAuth } from "component/utilis/apiWrapper";
import EmailImage from "assets/images/ContactUsEmail.svg";
import TimeImage from "assets/images/ContactUsTime.svg";
import LocationImage from "assets/images/ContactUsLocation.svg";
import LocationPhone from "assets/images/ContactUsPhone.svg";
import { useState } from "react";
import "./ContactUsStyle.scss";

const ContactUs = () => {
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState(false);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const sendMessage = async () => {
    setIsLoading(true);
    const response = await postApiWithoutAuth(
      "contact-us/create/",
      contactData
    );
    setIsLoading(false);

    if (!response.success) {
      message.error(`${response.message.data.error}`);
      return;
    }
    message.success("Message send successfully");
  };
  const phoneNumberRegex = /^\+?[0-9+]*$/;
  const handlePhoneNumberChange = (e) => {
    const { name, value } = e.target;
    if (phoneNumberRegex.test(value) || value === "") {
      setContactData({ ...contactData, [name]: value });
    }
  };

  return (
    <>
      <div className="contactUsMainStyle">
        <div className="contactContainer">
          <div className="contactUsFormStyle">
            <div className="masterAccountHeading">Contact Us </div>
            <div className="feelFreeTextStyle">
              Feel free to contact us and we will get back to you as soon as we
              can.
            </div>
            <div className="masterinputFieldContainer">
              <div className="masterinputFieldContainerLeft">
                <div className="contactTagStyle">Name:</div>
                <div>
                  <Input
                    placeholder="Enter your name"
                    className="masterAccountInputStyle"
                    name="name"
                    value={contactData.name}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
              <div className="masterinputFieldContainerRight">
                <div className="contactTagStyle">Email </div>
                <div>
                  <Input
                    placeholder="Enter your email"
                    className="masterAccountInputStyle"
                    name="email"
                    value={contactData.email}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="messageContainerStyle">
              <div className="contactTagStyle">Message</div>
              <TextArea
                rows={4}
                style={{ width: "95%", marginTop: "1%" }}
                placeholder="Hi there! I would like to talk you some................"
                name="message"
                value={contactData.message}
                onChange={handleOnChange}
              />
            </div>
            <MetaSyncButton
              text="Send"
              onClick={sendMessage}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="contactUsSecContainerStyle">
          <div className="contactUsSecondContainerStyle">
            <div className="contactFooterContainerStyle">
              <div style={{ width: "20%" }}>
                <div className="contactUsFooterHeading">Opening Hours</div>
                <div className="openingHourTimeStyle">
                  <div>
                    <img
                      src={TimeImage}
                      alt="timeImage"
                      style={{ paddingTop: "5px" }}
                    />
                  </div>
                  <div className="openingHourContainerStyle">
                    <div>Monday-Friday</div>
                    <div>9am-5pm</div>
                    <div>Weekenc</div>
                  </div>
                </div>
              </div>
              <div style={{ width: "20%" }}>
                <div className="contactUsFooterHeading">Address</div>
                <div className="openingHourTimeStyle">
                  <div>
                    <img
                      src={LocationImage}
                      alt="timeImage"
                      style={{ paddingTop: "5px" }}
                    />
                  </div>
                  <div className="openingHourContainerStyle">
                    <div>
                      Jln. Letjend Suparman 51 Kediri, jawa Timur 64133,Canada
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "20%" }}>
                <div className="contactUsFooterHeading">Support</div>
                <div className="openingHourTimeStyle">
                  <div>
                    <img
                      src={EmailImage}
                      alt="timeImage"
                      style={{ paddingTop: "5px" }}
                    />
                  </div>
                  <div className="openingHourContainerStyle">
                    <div>hello@lahoi.com</div>
                  </div>
                </div>
                <div className="openingHourTimeStyle">
                  <div>
                    <img src={LocationPhone} alt="timeImage" />
                  </div>
                  <div className="openingHourContainerStyle">
                    <div>+62 21 6539-0605</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
